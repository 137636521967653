.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .notary-details h3 {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 30px;
  font-weight: bold;
  line-height: 37px;
  letter-spacing: normal; }

.standard-styles .notary-details img.seal {
  display: block;
  height: 175px;
  object-fit: contain; }

.standard-styles .notary-details .amalfi,
.standard-styles .notary-details .ballpoint,
.standard-styles .notary-details .stylededit,
.standard-styles .notary-details .whiteoleander {
  font-size: 72px;
  margin: 0 0 32px 0; }
