.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .not-found {
  margin-top: -50px;
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 32px; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .not-found {
      margin-top: -100px; } }
  .standard-styles .not-found .not-found__hero {
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 100px;
    font-weight: bold;
    line-height: 124px;
    letter-spacing: normal;
    color: #5f5f5f;
    margin-bottom: 16px; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .not-found .not-found__hero {
        font-family: 'AvertaPE', 'sans-serif';
        font-size: 168px;
        font-weight: bold;
        line-height: 207px;
        letter-spacing: normal; } }
  .standard-styles .not-found .not-found__subheading, .standard-styles .not-found .not-found__body {
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
    letter-spacing: normal;
    margin-bottom: 8px;
    color: #565656; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .not-found .not-found__subheading, .standard-styles .not-found .not-found__body {
        font-family: 'AvertaPE', 'sans-serif';
        font-size: 32px;
        font-weight: bold;
        line-height: 39px;
        letter-spacing: normal; } }
  .standard-styles .not-found .not-found__body {
    font-weight: normal !important;
    margin-bottom: 24px; }
