.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .document-pop .document-pop__actions {
  margin-top: 20px; }
  .standard-styles .document-pop .document-pop__actions .actions__button {
    font-size: 12px;
    width: 100%; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .document-pop .document-pop__actions {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end; }
      .standard-styles .document-pop .document-pop__actions .actions__button {
        width: auto; }
      .standard-styles .document-pop .document-pop__actions > button:first-child {
        margin-right: 10px; }
      .standard-styles .document-pop .document-pop__actions > button:last-child {
        margin-left: 10px; } }

.standard-styles .document-pop.document-pop__disable {
  pointer-events: none; }
