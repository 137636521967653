.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .radio__label {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 16px;
  font-weight: normal;
  line-height: 1.45;
  letter-spacing: normal; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .radio__label {
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 24px;
      font-weight: normal;
      line-height: 1.17;
      letter-spacing: normal; } }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .radio, .standard-styles .tooltipflow {
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  cursor: pointer; }
  .standard-styles .radio span, .standard-styles .tooltipflow span {
    display: inline-block; }
  .standard-styles .radio:hover .radio__indicator, .standard-styles .tooltipflow:hover .radio__indicator {
    border: 2px solid #888888; }
  .standard-styles .radio:focus .radio__indicator, .standard-styles .tooltipflow:focus .radio__indicator {
    border-color: #4c84f5; }

.standard-styles .radio__label {
  padding: 0;
  line-height: 1;
  background-color: transparent !important; }

.standard-styles .radio__indicator {
  width: 24px;
  min-width: 24px;
  height: 24px;
  margin: -4px 8px;
  position: relative;
  border-radius: 15px;
  border: 2px solid #d2d2d2;
  z-index: 2; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .radio__indicator {
      margin: 0 16px; } }

.standard-styles .align-with-radio {
  margin-left: 40px; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .align-with-radio {
      margin-left: 56px; } }

.standard-styles .radio__check {
  width: 100%;
  height: 100%;
  display: block !important;
  margin: 0;
  padding: 0;
  z-index: 1;
  position: relative;
  top: 0;
  left: 0;
  opacity: 0.99;
  background-size: cover;
  background-image: url("../../../icons/svg/RadioButton.svg"); }

.standard-styles .radio__check--inactive {
  background-image: url("../../../icons/svg/RadioButtonDeselected.svg"); }

.standard-styles .radio-disabled {
  background-color: #d2d2d2;
  color: #d2d2d2; }

.standard-styles .tooltipflow {
  margin-top: 6px; }
  .standard-styles .tooltipflow .blocker {
    width: 24px;
    min-width: 24px;
    height: 24px;
    margin: -4px 16px; }
  .standard-styles .tooltipflow .tooltip {
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 13px;
    font-weight: normal;
    line-height: 1.23;
    letter-spacing: normal;
    background-color: #f7f7f7;
    padding: 6px 8px; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .tooltipflow .tooltip {
        font-size: 16px;
        line-height: 1;
        padding: 12px 6px; } }
