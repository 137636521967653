.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles #review__id .update__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 22px 20px 22px 15px; }
  .standard-styles #review__id .update__wrapper .update__item {
    padding-bottom: 15px;
    line-height: 16px;
    margin: 0; }
    .standard-styles #review__id .update__wrapper .update__item:first-child {
      display: flex;
      flex-direction: column; }
      .standard-styles #review__id .update__wrapper .update__item:first-child > label {
        font-family: 'AvertaPE', 'sans-serif';
        font-size: 12px;
        font-weight: bold;
        line-height: 1;
        letter-spacing: normal;
        text-transform: uppercase;
        margin-bottom: 10px;
        margin-top: -5px;
        line-height: 16px; }
    .standard-styles #review__id .update__wrapper .update__item .text-input {
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 24px;
      font-weight: normal;
      line-height: 1;
      letter-spacing: normal; }
    .standard-styles #review__id .update__wrapper .update__item:last-child {
      padding: 0;
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 12px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: normal; }
  .standard-styles #review__id .update__wrapper .update-error {
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 12px;
    font-weight: bold;
    line-height: 1.33;
    letter-spacing: normal;
    text-transform: uppercase; }
  .standard-styles #review__id .update__wrapper .update-error--red {
    color: #ff5a5a; }
