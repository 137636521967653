.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .mobilenav-container {
  height: 100%; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .mobilenav-container {
      display: none; } }

.standard-styles .mobile-navbar {
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  color: #4c84f5;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  padding: 0 20px; }
  .standard-styles .impersonating .mobile-navbar {
    background: rgba(255, 0, 0, 0.3); }

.standard-styles .mobile-navbar__left {
  margin-left: 20px;
  margin-right: auto; }

.standard-styles .mobile-navbar__center {
  display: block;
  margin: auto; }
  @media only screen and (min-width: 0px) and (max-width: 599px) {
    .standard-styles .mobile-navbar__center {
      padding-right: 3rem; } }

.standard-styles .mobile-navbar__brand {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: normal;
  display: block;
  width: 230px;
  height: 24px;
  max-width: 100%;
  margin-top: 12px;
  margin-bottom: 12px; }
  .standard-styles .mobile-navbar__brand a,
  .standard-styles .mobile-navbar__brand a:link,
  .standard-styles .mobile-navbar__brand a:visited,
  .standard-styles .mobile-navbar__brand a:focus,
  .standard-styles .mobile-navbar__brand a:hover,
  .standard-styles .mobile-navbar__brand a:active {
    color: #4c84f5;
    text-decoration: none;
    text-transform: uppercase; }
  .standard-styles .mobile-navbar__brand a:hover {
    text-decoration: none; }
  .standard-styles .mobile-navbar__brand a {
    text-transform: none !important; }

.standard-styles .mobile-navbar__brand-link {
  display: block; }

.standard-styles .mobile-navbar__right {
  position: absolute;
  right: 20px; }
  .standard-styles .mobile-navbar__right:hover {
    cursor: pointer; }

.standard-styles .mobile-navbar__hamburger-icon {
  width: 24px;
  height: 18px;
  background-size: cover;
  background-image: url("../../icons/svg/icon-hamburger.svg");
  display: block; }

.standard-styles .backdrop {
  background-color: #00008c !important; }

.standard-styles .dropdown {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 8px;
  overflow: auto;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out; }
  .standard-styles .dropdown a,
  .standard-styles .dropdown a:link,
  .standard-styles .dropdown a:visited,
  .standard-styles .dropdown a:focus,
  .standard-styles .dropdown a:hover,
  .standard-styles .dropdown a:active {
    color: #ffffff;
    text-decoration: none;
    text-transform: uppercase; }
  .standard-styles .dropdown a:hover {
    text-decoration: underline; }
  .standard-styles .dropdown .dropdown__list {
    list-style-type: none;
    clear: both; }
    .standard-styles .dropdown .dropdown__list li {
      margin-bottom: 32px; }
      @media only screen and (min-width: 600px) and (max-width: 999px) {
        .standard-styles .dropdown .dropdown__list li {
          font-family: 'AvertaPE', 'sans-serif';
          font-size: 36px;
          font-weight: normal;
          line-height: 42px;
          letter-spacing: normal; } }
      @media only screen and (min-width: 0px) and (max-width: 599px) {
        .standard-styles .dropdown .dropdown__list li {
          font-family: 'AvertaPE', 'sans-serif';
          font-size: 24px;
          font-weight: normal;
          line-height: 28px;
          letter-spacing: normal; } }
  .standard-styles .dropdown .dropdown__close-button {
    width: 48px;
    height: 48px;
    margin-right: 8px;
    float: right;
    background-size: cover;
    background-image: url("../../icons/svg/icon-no.svg"); }

.standard-styles .dropdown--hidden {
  margin-top: -9999px; }

.standard-styles .mobile-navbar--white {
  background-color: #ffffff;
  margin-top: 0;
  margin-bottom: 1px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06);
  z-index: 5;
  padding: 12px 24px;
  height: unset; }
