@media only screen and (min-width: 0px) and (max-width: 599px) {
  .standard-styles .checkout-form {
    margin: 0 8px 16px 8px;
    padding: 6px 16px; } }

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .standard-styles .checkout-form {
    margin: 0 16px 16px 16px;
    padding: 21px 24px; } }

@media only screen and (min-width: 1000px) {
  .standard-styles .checkout-form {
    margin: 0 96px 32px 96px;
    padding: 16px 80px 80px 80px; } }

.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

@media only screen and (min-width: 0px) and (max-width: 599px) {
  .standard-styles .checkout-form {
    margin: 0 8px 16px 8px;
    padding: 6px 16px; } }

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .standard-styles .checkout-form {
    margin: 0 16px 16px 16px;
    padding: 21px 24px; } }

@media only screen and (min-width: 1000px) {
  .standard-styles .checkout-form {
    margin: 0 96px 32px 96px;
    padding: 16px 80px 80px 80px; } }

.standard-styles .checkout-form {
  background-color: #ffffff;
  width: 100%;
  max-width: 100%;
  margin-top: 24px;
  padding: 48px 24px;
  border: solid 2px #e6e6e6;
  border-radius: 5px;
  box-shadow: 0 0 9px 2px rgba(0, 0, 0, 0.06); }
  @media only screen and (min-width: 0px) and (max-width: 599px) {
    .standard-styles .checkout-form {
      margin: 0 0 40px 0;
      padding-bottom: 36px;
      box-shadow: unset;
      border: unset;
      border-radius: 0;
      order: 2; } }
  @media only screen and (min-width: 600px) and (max-width: 999px) {
    .standard-styles .checkout-form {
      margin: 0 0 40px 0;
      padding-bottom: 36px;
      box-shadow: unset;
      border: unset;
      border-radius: 0;
      order: 2; } }
  @media only screen and (min-width: 1000px) {
    .standard-styles .checkout-form {
      flex: 1 2 auto;
      margin-right: 48px;
      padding: 48px 52px;
      margin-left: 0;
      margin-top: 0;
      width: 492px;
      max-width: 492px; } }
  .standard-styles .checkout-form h4 {
    margin-top: 64px;
    margin-bottom: 32px; }
  .standard-styles .checkout-form h6 {
    margin: 0 0 24px 0;
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 32px;
    font-weight: 600;
    line-height: 39px;
    letter-spacing: normal; }
  .standard-styles .checkout-form p.subtext {
    margin-bottom: 48px; }
  .standard-styles .checkout-form .button--finalize {
    margin-bottom: 72px; }
  .standard-styles .checkout-form .stripe-label {
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 16px;
    font-weight: normal;
    line-height: 1;
    letter-spacing: 1.3px;
    color: #333647;
    margin-bottom: 9px; }
  .standard-styles .checkout-form .elements {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center; }
    .standard-styles .checkout-form .elements .stripe-container {
      border: solid 2px #ebecf0;
      border-radius: 5px;
      padding: 11px 16px;
      flex-grow: 1;
      flex-shrink: 0; }
      .standard-styles .checkout-form .elements .stripe-container:first-child {
        display: flex;
        flex-grow: 3;
        margin-bottom: 24px;
        width: 100%; }
        .standard-styles .checkout-form .elements .stripe-container:first-child .brand {
          width: 50px;
          height: 30px;
          margin-right: 16px; }
        .standard-styles .checkout-form .elements .stripe-container:first-child > div {
          flex: 1; }
        @media only screen and (min-width: 0px) and (max-width: 599px) {
          .standard-styles .checkout-form .elements .stripe-container:first-child {
            width: 100%; }
            .standard-styles .checkout-form .elements .stripe-container:first-child .brand {
              width: 30px;
              height: 21px;
              align-self: center; } }
        @media only screen and (min-width: 600px) and (max-width: 999px) {
          .standard-styles .checkout-form .elements .stripe-container:first-child {
            width: 100%; } }
      .standard-styles .checkout-form .elements .stripe-container:nth-child(2) {
        margin-right: 24px; }
    .standard-styles .checkout-form .elements .stripe-container--error {
      border-color: #ff5a5a; }
  .standard-styles .checkout-form .stripe-errors ul {
    list-style-type: none;
    padding-left: 0; }
    .standard-styles .checkout-form .stripe-errors ul li {
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 18px;
      font-weight: normal;
      line-height: 1;
      letter-spacing: normal; }
      .standard-styles .checkout-form .stripe-errors ul li .warning-icon, .standard-styles .checkout-form .stripe-errors ul li .success-icon {
        display: inline-block;
        margin-right: 6px;
        margin-bottom: -9px;
        background-image: url("../../../icons/svg/icon-warning.svg");
        background-size: 32px 32px;
        width: 32px;
        height: 32px; }
      .standard-styles .checkout-form .stripe-errors ul li .success-icon {
        background-size: 16px 16px;
        width: 16px;
        height: 16px;
        margin-bottom: -2px;
        background-image: url("../../../icons/svg/icon-check-green.svg"); }
  .standard-styles .checkout-form .form__header {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    text-align: center;
    width: 100%;
    padding-top: 24px;
    padding-bottom: 24px;
    margin-bottom: 48px;
    background-color: #f4f5f7; }
    @media only screen and (min-width: 0px) and (max-width: 599px) {
      .standard-styles .checkout-form .form__header {
        margin-bottom: 24px; } }
    @media only screen and (min-width: 1000px) {
      .standard-styles .checkout-form .form__header {
        text-align: left;
        align-items: flex-start;
        padding-top: 0;
        background: #ffffff url("../../../icons/icon-dashed-border.png") repeat-x bottom; } }
    .standard-styles .checkout-form .form__header .checkout-form__description {
      width: 80%;
      padding: 0 16px; }
      @media only screen and (min-width: 0px) and (max-width: 599px) {
        .standard-styles .checkout-form .form__header .checkout-form__description p {
          display: none; } }
      @media only screen and (min-width: 1000px) {
        .standard-styles .checkout-form .form__header .checkout-form__description {
          padding-left: 0; } }
    .standard-styles .checkout-form .form__header h5 {
      margin: 0;
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 24px;
      font-weight: 600;
      line-height: 1.17;
      letter-spacing: normal; }
    .standard-styles .checkout-form .form__header ul {
      padding-left: 0;
      margin-bottom: 24px;
      text-align: left;
      list-style-position: inside; }
      @media only screen and (min-width: 1000px) {
        .standard-styles .checkout-form .form__header ul {
          margin-bottom: 0; } }
      .standard-styles .checkout-form .form__header ul li {
        font-family: 'AvertaPE', 'sans-serif';
        font-size: 16px;
        font-weight: normal;
        line-height: 1.63;
        letter-spacing: normal; }
  .standard-styles .checkout-form .pricebox {
    display: flex;
    flex-direction: column;
    max-width: 100%; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .checkout-form .pricebox {
        flex-direction: row; }
        .standard-styles .checkout-form .pricebox p {
          padding-right: 12px; } }
    @media only screen and (min-width: 0px) and (max-width: 599px) {
      .standard-styles .checkout-form .pricebox p {
        display: none; } }
    .standard-styles .checkout-form .pricebox .pricebox_total {
      display: flex;
      flex-direction: column; }
      @media only screen and (min-width: 1000px) {
        .standard-styles .checkout-form .pricebox .pricebox_total {
          width: 270px; } }
      @media only screen and (min-width: 1200px) {
        .standard-styles .checkout-form .pricebox .pricebox_total {
          width: 180px; } }
      .standard-styles .checkout-form .pricebox .pricebox_total .pricebox__label {
        font-family: 'AvertaPE', 'sans-serif';
        font-size: 16px;
        font-weight: normal;
        line-height: 1.5;
        letter-spacing: 1.3px;
        text-transform: uppercase;
        color: #a9b0b8; }
        @media only screen and (min-width: 0px) and (max-width: 599px) {
          .standard-styles .checkout-form .pricebox .pricebox_total .pricebox__label {
            display: none; } }
      .standard-styles .checkout-form .pricebox .pricebox_total .pricebox__price {
        font-family: 'AvertaPE', 'sans-serif';
        font-size: 32px;
        font-weight: bold;
        line-height: 1.22;
        letter-spacing: 0.5px;
        color: #414042; }
        @media only screen and (min-width: 0px) and (max-width: 599px) {
          .standard-styles .checkout-form .pricebox .pricebox_total .pricebox__price {
            color: #4c84f5; } }
  .standard-styles .checkout-form .promobox {
    display: inline-block;
    margin-top: 24px;
    margin-bottom: 32px; }
    .standard-styles .checkout-form .promobox .stripe-errors {
      margin-top: -16px; }
      @media only screen and (min-width: 0px) and (max-width: 599px) {
        .standard-styles .checkout-form .promobox .stripe-errors {
          margin-top: unset; } }
    .standard-styles .checkout-form .promobox .promobox__label {
      cursor: pointer;
      color: #4c84f5;
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 16px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: 0.2px; }
    .standard-styles .checkout-form .promobox .code-flow {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start; }
      .standard-styles .checkout-form .promobox .code-flow .promobox__input {
        width: 176px;
        margin-right: 20px;
        display: inline;
        border: none;
        outline: none; }
        .standard-styles .checkout-form .promobox .code-flow .promobox__input::placeholder {
          font-family: sans-serif;
          font-size: 15px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: #828282; }
      .standard-styles .checkout-form .promobox .code-flow .promobox__apply {
        font-family: 'AvertaPE', 'sans-serif';
        font-size: 18px;
        font-weight: bold;
        line-height: 1;
        letter-spacing: normal;
        height: 56px;
        text-transform: uppercase;
        color: #333647;
        padding: 19px 24px;
        background-color: #f4f5f7;
        cursor: pointer;
        border-radius: 2px; }
  .standard-styles .checkout-form .contact-info {
    text-align: center;
    display: block;
    width: 100%;
    margin: 16px 0; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .checkout-form .contact-info {
        display: none; } }
  .standard-styles .checkout-form .payment__split {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 24px 0; }
    .standard-styles .checkout-form .payment__split .line {
      width: 88px;
      height: 1px;
      border: solid 1px #e6e6e6; }
    .standard-styles .checkout-form .payment__split .text {
      width: 18px;
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 18px;
      font-weight: normal;
      line-height: normal;
      letter-spacing: 0.2px;
      color: #333647;
      margin: 0 24px; }
  .standard-styles .checkout-form .credit-card-wrapper {
    margin-top: 24px;
    display: flex;
    justify-content: flex-start; }
    .standard-styles .checkout-form .credit-card-wrapper .credit-card {
      width: 50px;
      height: 30px;
      margin: 0 4px; }
      .standard-styles .checkout-form .credit-card-wrapper .credit-card:first-child {
        margin-left: 0; }
      .standard-styles .checkout-form .credit-card-wrapper .credit-card:last-child {
        margin-right: 0; }
      .standard-styles .checkout-form .credit-card-wrapper .credit-card.inactive {
        opacity: 0.5; }
      .standard-styles .checkout-form .credit-card-wrapper .credit-card.active {
        opacity: 1; }
  .standard-styles .checkout-form .cc-visa:before {
    content: url("~@a/images/icon-visa.svg"); }
  .standard-styles .checkout-form .cc-mastercard:before {
    content: url("~@a/images/icon-master-card.svg"); }
  .standard-styles .checkout-form .cc-american-express:before {
    content: url("~@a/images/icon-amex.svg"); }
  .standard-styles .checkout-form .cc-discover:before {
    content: url("~@a/images/icon-discover.svg"); }
  .standard-styles .checkout-form .cc-credit-card:before {
    content: url("../../../icons/icon-unknown.png"); }
  .standard-styles .checkout-form .lockflow {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
    width: 100%; }
    .standard-styles .checkout-form .lockflow .lock {
      margin-right: 8px; }
      .standard-styles .checkout-form .lockflow .lock:before {
        content: url("../../../icons/svg/icon-lock-large.svg");
        position: relative;
        top: 2px; }
    .standard-styles .checkout-form .lockflow .locklabel {
      text-align: left;
      margin: 0;
      padding-left: 16px;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: normal; }
