.standard-styles .button-flow {
  display: flex;
  flex-direction: column-reverse;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 40px; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .button-flow {
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start; } }
  .standard-styles .button-flow .button {
    margin-bottom: 4px; }
    @media only screen and (min-width: 0px) and (max-width: 599px) {
      .standard-styles .button-flow .button {
        width: 100%; } }
    @media only screen and (min-width: 600px) and (max-width: 999px) {
      .standard-styles .button-flow .button {
        width: 100%; } }
    @media only screen and (min-width: 1000px) {
      .standard-styles .button-flow .button {
        margin-top: 28px; } }

.standard-styles .button-flow--compressed {
  margin-top: 0; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .button-flow--compressed {
      margin-top: -20px; } }

.standard-styles .button-flow--grow {
  width: 100%; }
  .standard-styles .button-flow--grow .button {
    flex-grow: 2; }

.standard-styles .button-flow--right {
  justify-content: flex-end; }

@media only screen and (min-width: 1000px) {
  .standard-styles .button-flow--popup {
    justify-content: flex-end; } }
