.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .wizard-row {
  display: block;
  margin: 18px 0; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .wizard-row {
      margin: 32px 0; } }
  .standard-styles .wizard-row .wizard-row__subtext {
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 16px;
    font-weight: normal;
    line-height: 1;
    letter-spacing: normal;
    margin: 8px 0 0 0; }

.standard-styles .wizard-row--mobile-large {
  margin: 32px 0; }
