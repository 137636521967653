.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .check .check__label {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 16px;
  font-weight: normal;
  line-height: 1.45;
  letter-spacing: normal; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .check .check__label {
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 24px;
      font-weight: normal;
      line-height: 1.17;
      letter-spacing: normal; } }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .check {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
  cursor: pointer;
  margin-top: 16px; }
  .standard-styles .check.check--centered {
    align-items: center; }
  .standard-styles .check:focus .check__indicator {
    border-color: #ffffff;
    outline: #4c84f5 solid 2px; }
    .standard-styles .check:focus .check__indicator:hover {
      border-color: #ffffff; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .check {
      padding-top: 8px;
      margin-top: 0; } }
  .standard-styles .check input[type='checkbox'] {
    display: none; }
  .standard-styles .check .check__indicator {
    width: 30px;
    min-width: 30px;
    height: 30px;
    margin: 0 16px -4px 16px;
    background-color: #ffffff;
    border: 2px solid #d2d2d2;
    z-index: 1;
    display: inline-block; }
  .standard-styles .check .check__checkmark {
    width: 100%;
    height: 100%;
    display: none;
    margin: 0;
    padding: 0;
    z-index: 1;
    position: relative;
    top: 0;
    left: 0;
    opacity: 0.99;
    background-size: cover;
    background-image: url("../../../icons/svg/CheckBox.svg"); }
  .standard-styles .check .check__checkmark--checked {
    display: block !important; }
  .standard-styles .check .check__label {
    cursor: pointer;
    font-weight: normal; }
  .standard-styles .check:hover .check__indicator {
    border: 2px solid #888888; }
  .standard-styles .check .check__toggle-info-handle {
    display: inline-block;
    font-size: 14px;
    line-height: 28px;
    margin-left: 16px;
    text-transform: uppercase;
    color: #4c84f5; }
  .standard-styles .check .check__triangle {
    display: inline-block;
    position: relative;
    top: -1px;
    margin-left: 8px; }

.standard-styles .disabled {
  background-color: #d2d2d2 !important; }
