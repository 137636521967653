.standard-styles .button {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: normal;
  font-variant: small-caps; }

.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .button {
  min-height: 48px;
  max-height: max-content;
  padding: 16px 36px;
  margin-right: 10px;
  text-transform: uppercase;
  border: none;
  cursor: pointer; }
  .standard-styles .button:focus {
    outline: #4c84f5 solid 2px; }

.standard-styles .button--primary {
  color: #ffffff;
  background-color: #3752a9;
  box-shadow: 0 2px 6px 0 rgba(87, 159, 253, 0.1); }
  .standard-styles .button--primary:hover {
    background-color: #4e8ee3; }
  .standard-styles .button--primary:disabled {
    color: #ffffff;
    background-color: #d2d2d2; }

.standard-styles .button--secondary {
  color: #3752a9;
  background-color: #ffffff;
  border: 2px solid #3752a9;
  box-shadow: 0 2px 6px 0 rgba(87, 159, 253, 0.1); }
  .standard-styles .button--secondary:hover {
    background-color: #f2f2f9; }
  .standard-styles .button--secondary:disabled {
    color: #ffffff;
    border-color: #d2d2d2; }

.standard-styles .button--tertiary, .standard-styles .button--faux-link {
  color: #324a9a;
  background-color: unset; }
  .standard-styles .button--tertiary:hover, .standard-styles .button--faux-link:hover {
    text-decoration-line: underline;
    text-decoration-color: #324a9a; }
  .standard-styles .button--tertiary:disabled, .standard-styles .button--faux-link:disabled {
    color: #d2d2d2;
    text-decoration: none; }

.standard-styles .button--error {
  color: #ff5a5a; }
  .standard-styles .button--error:hover {
    text-decoration-color: #ff5a5a; }

.standard-styles .button--green {
  color: #ffffff;
  background-color: #5dd8b6; }
  .standard-styles .button--green:hover {
    background-color: #72e1c3; }
  .standard-styles .button--green:disabled {
    color: #ffffff;
    background-color: #d2d2d2; }

.standard-styles .button--red {
  color: #ffffff;
  background-color: #ff5a5a; }
  .standard-styles .button--red:hover {
    background-color: #ff7979;
    text-decoration-color: #ff7979; }
  .standard-styles .button--red:disabled {
    color: #ffffff;
    background-color: #d2d2d2; }

.standard-styles .button--inline {
  display: inline; }

.standard-styles .button--inline--small {
  display: inline-block;
  padding: 0 16px;
  margin: 0;
  min-height: 32px;
  height: 32px; }
  .standard-styles .button--inline--small:first-child {
    padding-left: 0; }

.standard-styles .button--nopadding {
  padding: 0; }

.standard-styles .button--nomargin {
  margin: 0; }

.standard-styles .button--fullwidth {
  width: 100%; }

.standard-styles .button--paybutton {
  border-radius: 2px;
  min-height: 60px !important;
  padding: 0 36px !important;
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 24px;
  font-weight: bold;
  line-height: normal;
  letter-spacing: 2px;
  color: #f7f7f7;
  text-transform: none;
  font-variant: none; }

@media only screen and (min-width: 1000px) {
  .standard-styles .button--file {
    min-width: 108px; } }

.standard-styles .button--payment-request {
  margin-top: 16px;
  border-radius: 100px; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .button--payment-request {
      display: none; } }

.standard-styles .button--payment-request--nopad {
  margin-top: 0; }

.standard-styles .button--apple-pay, .standard-styles .button--google-pay {
  background-color: #000000;
  color: #ffffff; }
  .standard-styles .button--apple-pay .pay-icon, .standard-styles .button--google-pay .pay-icon {
    display: inline-block;
    position: relative;
    top: -4px;
    text-transform: none;
    font-variant: normal;
    font-size: 16px;
    font-weight: normal; }
    .standard-styles .button--apple-pay .pay-icon:after, .standard-styles .button--google-pay .pay-icon:after {
      display: inline-block;
      content: url("../../../icons/svg/icon-apple-pay.svg");
      position: relative;
      top: 4px;
      left: 4px; }

.standard-styles .button--google-pay {
  background-color: #ffffff;
  border: 2px solid #dadce0; }
  .standard-styles .button--google-pay .pay-icon {
    top: -6px;
    color: #4a4a4a; }
    .standard-styles .button--google-pay .pay-icon:after {
      content: url("../../../icons/svg/icon-google-pay.svg");
      left: 6px; }

.standard-styles .button--faux-link {
  color: #000000;
  text-decoration: underline;
  text-decoration-color: #000000;
  margin-top: 8px; }
  .standard-styles .button--faux-link:hover {
    text-decoration-color: #000000; }

.standard-styles .button--pull-right {
  float: right; }

.standard-styles .button--tertiary.button--red, .standard-styles .button--red.button--faux-link {
  background-color: transparent;
  color: #ff5a5a;
  padding: 0; }

.standard-styles .button--no-right-margin {
  margin-right: 0 !important; }

.standard-styles .button--focus-outline-inside:focus {
  outline: none;
  box-shadow: inset 0 0 0 2px #000000; }
