.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .waiting-room #publisher {
  width: 100%;
  height: 250px; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .waiting-room #publisher {
      width: 370px; } }
