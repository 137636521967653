.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .intro-verification {
  color: #e2e2e2;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #262626; }
  .standard-styles .intro-verification .intro-verification__loading {
    position: fixed; }
  .standard-styles .intro-verification input[type='file'] {
    display: none; }
  @media only screen and (min-width: 1200px) {
    .standard-styles .intro-verification {
      padding: 80px 70px 47px 70px;
      height: auto;
      background-color: transparent; } }
  .standard-styles .intro-verification .intro__accepted-forms > span,
  .standard-styles .intro-verification .intro__tips > span {
    display: block;
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 18px;
    font-weight: bold;
    line-height: 26px;
    letter-spacing: normal; }
  .standard-styles .intro-verification .intro__accepted-forms > ul,
  .standard-styles .intro-verification .intro__tips > ul {
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 18px;
    font-weight: normal;
    line-height: 26px;
    letter-spacing: normal;
    padding-left: 18px; }
  @media only screen and (min-width: 1200px) {
    .standard-styles .intro-verification .intro__accepted-forms,
    .standard-styles .intro-verification .intro__tips {
      margin: 40px 0 0 0; } }
  .standard-styles .intro-verification .intro__accepted-forms {
    color: #000000;
    display: none; }
    @media only screen and (min-width: 1200px) {
      .standard-styles .intro-verification .intro__accepted-forms {
        display: block;
        color: inherit; } }
  .standard-styles .intro-verification .popup-fuzz .intro__accepted-forms {
    display: block;
    margin: 0 auto; }
  .standard-styles .intro-verification .intro__actions {
    text-align: center;
    bottom: 20px;
    justify-self: flex-end;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
    .standard-styles .intro-verification .intro__actions > button {
      width: 100%;
      margin: 0;
      padding: 0; }
    .standard-styles .intro-verification .intro__actions > button:last-child {
      margin-bottom: 10px; }
    @media only screen and (min-width: 1200px) {
      .standard-styles .intro-verification .intro__actions {
        display: none; } }
