.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .signature-display-flow {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 24px; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .signature-display-flow {
      align-items: flex-end;
      flex-direction: row;
      margin-top: 64px; } }
  .standard-styles .signature-display-flow .flow__box .signature-img {
    object-fit: contain;
    min-width: 120px;
    max-width: 100%;
    height: 160px;
    margin-bottom: -85px; }
    .standard-styles .signature-display-flow .flow__box .signature-img.box__signature {
      min-width: 240px; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .signature-display-flow .flow__box .signature-img {
        max-width: 350px; } }
  @media only screen and (min-width: 0px) and (max-width: 999px) {
    .standard-styles .signature-display-flow .flow__box:first-child {
      margin-bottom: 48px; } }
  @media only screen and (min-width: 1000px) {
    .standard-styles .signature-display-flow .flow__box:first-child {
      margin-right: 64px; } }
  .standard-styles .signature-display-flow .flow__box .box__line {
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 24px;
    font-weight: normal;
    line-height: 30px;
    letter-spacing: normal;
    padding-top: 8px;
    border-top: 2px dashed #c3c3c3;
    text-align: center; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .signature-display-flow .flow__box .box__line {
        text-align: left; } }
