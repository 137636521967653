.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .popup h4 {
  font-family: 'Tiempos Headline', 'serif';
  font-size: 32px;
  font-weight: normal;
  line-height: 40px;
  letter-spacing: normal; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .popup h4 {
      font-family: 'Tiempos Headline', 'serif';
      font-size: 48px;
      font-weight: 500;
      line-height: 1;
      letter-spacing: normal; } }

@media only screen and (min-width: 1000px) {
  .standard-styles .popup h4 {
    font-family: 'Tiempos Headline', 'serif';
    font-size: 32px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: normal; } }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .popup {
  width: 343px;
  position: relative;
  background-color: #ffffff;
  color: black;
  padding: 32px 24px 24px 33px;
  z-index: 999;
  border-radius: 4px; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .popup {
      width: 640px;
      padding: 32px 33px 24px 33px; } }
  .standard-styles .popup .close-icon {
    position: absolute;
    top: 8px;
    right: 8px;
    cursor: pointer;
    width: 24px;
    height: 24px;
    object-fit: contain;
    background-size: cover;
    background-image: url("../../icons/svg/icon-close.svg"); }
  .standard-styles .popup .wandaholder {
    margin-top: -72px;
    margin-bottom: 18px; }
  .standard-styles .popup h4 {
    line-height: 1;
    margin-bottom: 16px;
    margin-top: 0; }
  .standard-styles .popup p {
    margin-top: 8px; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .popup button {
      margin-top: 40px; } }

.standard-styles .popup--signature {
  text-align: center; }
  .standard-styles .popup--signature p {
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 20px;
    font-weight: 300;
    line-height: 28px;
    letter-spacing: normal;
    color: #000000;
    letter-spacing: 0;
    text-align: center; }
  .standard-styles .popup--signature button {
    float: right; }

.standard-styles .popup--top-padding {
  padding-top: 32px; }

.standard-styles .popup-fuzz {
  background-color: rgba(162, 162, 162, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 998;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center; }
