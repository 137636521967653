.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .video-session-flow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: #393939;
  height: 93px; }
  .standard-styles .video-session-flow > .video-feed {
    height: 93px;
    width: 124px;
    max-width: 25%; }
    .standard-styles .video-session-flow > .video-feed.inactive {
      display: none; }
    .standard-styles .video-session-flow > .video-feed > div {
      max-height: 100%;
      max-width: 100%; }
      .standard-styles .video-session-flow > .video-feed > div > h1.OT_name {
        height: 20px;
        display: initial;
        top: unset;
        bottom: 3px;
        left: 3px;
        right: 3px;
        padding: 0;
        text-align: center;
        background-color: #ffffff;
        color: #000000;
        border-radius: 50px;
        opacity: 0.5;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        font-family: 'AvertaPE', 'sans-serif';
        font-size: 12px;
        font-weight: bold;
        line-height: 18px;
        letter-spacing: 1px; }
        @media only screen and (min-width: 1000px) {
          .standard-styles .video-session-flow > .video-feed > div > h1.OT_name {
            width: 80%;
            margin: 0 auto;
            height: 20px; } }
      @media only screen and (min-width: 1000px) {
        .standard-styles .video-session-flow > .video-feed > div {
          max-height: initial;
          max-width: initial; }
          .standard-styles .video-session-flow > .video-feed > div > div.OT_bar {
            opacity: 0; }
          .standard-styles .video-session-flow > .video-feed > div > button {
            border: none; } }
    @media only screen and (min-width: 1000px) {
      .standard-styles .video-session-flow > .video-feed {
        height: initial;
        width: initial;
        max-width: initial; } }
  @media only screen and (min-width: 1000px) {
    .standard-styles .video-session-flow {
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: flex-start;
      align-content: flex-start;
      background-color: initial;
      height: initial; } }
