.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .fail-wizard-page {
  min-height: 800px; }

.standard-styles .fail-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 300px; }
  .standard-styles .fail-info button {
    margin: 0 0 16px 0; }
  .standard-styles .fail-info .button--secondary {
    background: transparent; }
    .standard-styles .fail-info .button--secondary:hover {
      background: transparent; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .fail-info {
      width: 650px; } }
  .standard-styles .fail-info .icon {
    width: 96px;
    height: 96px;
    background-image: url("../../../../../icons/svg/icon-error.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .fail-info .icon {
        width: 120px;
        height: 120px; } }
  .standard-styles .fail-info .button-fail {
    color: #4c84f5;
    background: none; }
  .standard-styles .fail-info .fail-body {
    margin-bottom: 24px; }
  .standard-styles .fail-info .pointer-header {
    font-weight: bold;
    margin-bottom: 24px; }
