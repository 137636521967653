.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .wizard-page {
  padding-bottom: 96px; }

.standard-styles .wizard-page--full-height {
  height: 100%; }

.standard-styles .wizard-page--flex {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%; }

.standard-styles .wizard-close {
  position: absolute;
  top: -45px;
  left: 24px;
  background-size: contain;
  background-image: url("../../../icons/svg/icon-close-mobile.svg");
  background-repeat: no-repeat;
  z-index: 1;
  width: 16px;
  height: 16px; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .wizard-close {
      background-image: url("../../../icons/svg/icon-close-transparent.svg");
      top: 24px;
      left: calc(100% - 48px); } }
  .standard-styles .wizard-close.wizard-close-x {
    left: unset;
    top: 30px;
    right: 24px;
    background-image: url("../../../icons/svg/icon-close-transparent.svg"); }

.standard-styles .wizard-bottom {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 24px 40px 24px 80px;
  height: 96px;
  width: 100%;
  position: absolute;
  top: calc(100% - 96px);
  left: 0; }
  .standard-styles .wizard-bottom .label-box {
    min-width: max-content; }
    .standard-styles .wizard-bottom .label-box .label {
      display: inline-block;
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 24px;
      font-weight: normal;
      line-height: 1;
      letter-spacing: normal; }
      .standard-styles .wizard-bottom .label-box .label:first-child {
        margin-right: 32px; }
      .standard-styles .wizard-bottom .label-box .label .bold {
        font-weight: bold; }
  .standard-styles .wizard-bottom .button {
    width: 215px; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .wizard-bottom {
      box-shadow: 0 -3px 5px 0 rgba(210, 210, 210, 0.25); } }
