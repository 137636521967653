.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles input[type='text'] {
  -webkit-appearance: none; }

.standard-styles .text-input {
  height: 56px;
  word-break: normal;
  background-color: #ffffff;
  padding: 14px 16px;
  border: 2px solid #d2d2d2;
  border-radius: 2px;
  margin-right: 8px;
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 24px;
  font-weight: normal;
  line-height: 1;
  letter-spacing: normal; }
  @media only screen and (min-width: 0px) and (max-width: 599px) {
    .standard-styles .text-input {
      max-width: 100%; } }
  @media only screen and (min-width: 600px) and (max-width: 999px) {
    .standard-styles .text-input {
      max-width: 100%; } }
  .standard-styles .text-input:disabled {
    background-color: #d2d2d2; }
  .standard-styles .text-input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 300px #f6faff inset; }
  .standard-styles .text-input:focus {
    border-color: #ffffff;
    outline: #4c84f5 solid 2px; }

.standard-styles .text-input::placeholder, .standard-styles .text-input::-webkit-input-placeholder, .standard-styles .text-input--downshift::-webkit-input-placeholder, .standard-styles .text-input--downshift::placeholder {
  color: #a0a0a0;
  font-weight: 300;
  line-height: 28px; }

.standard-styles .text-input--2 {
  width: 108px; }

.standard-styles .text-input--3 {
  width: 143px; }

.standard-styles .text-input--4 {
  width: 222px; }

.standard-styles .text-input--5 {
  width: 250px;
  margin-bottom: 8px; }

.standard-styles .text-input--8 {
  width: 344px; }

.standard-styles .text-input--inline {
  display: inline-block;
  margin-bottom: 8px; }

.standard-styles .text-input--error {
  border: 2px solid #ff5a5a; }

.standard-styles .text-input--downshift {
  border: none;
  height: 42px;
  width: 300px;
  margin-bottom: 2px; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .text-input--downshift {
      height: 50px; } }
  .standard-styles .text-input--downshift:focus {
    outline: none !important; }
  .standard-styles .text-input--downshift::-webkit-input-placeholder {
    line-height: 42px; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .text-input--downshift::-webkit-input-placeholder {
        line-height: 50px; } }
  .standard-styles .text-input--downshift::placeholder {
    line-height: 21px; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .text-input--downshift::placeholder {
        line-height: 25px; } }

.standard-styles .text-input--fullsize {
  width: 100%; }
