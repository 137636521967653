.standard-styles .dashboard .dashboard__contentbox .dashboard__contentbox__item div:nth-child(3) {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: normal;
  font-variant: small-caps; }

.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .dashboard {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .dashboard {
      justify-content: center;
      flex-direction: row; } }
  .standard-styles .dashboard .dashboard__wandabox {
    margin-top: 24px;
    width: 100%;
    text-align: center;
    margin-bottom: 20px; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .dashboard .dashboard__wandabox {
        flex: 1 1 0;
        margin-top: 0;
        max-height: 100%;
        overflow: visible; } }
    .standard-styles .dashboard .dashboard__wandabox .vflow {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: center;
      align-content: flex-start;
      position: relative;
      z-index: 3; }
      .standard-styles .dashboard .dashboard__wandabox .vflow h1 {
        display: flex;
        justify-content: center;
        text-align: center;
        padding: 0;
        margin-top: 8px;
        width: 100%;
        margin-bottom: 12px; }
        @media only screen and (min-width: 1000px) {
          .standard-styles .dashboard .dashboard__wandabox .vflow h1 {
            margin-top: 32px; } }
      .standard-styles .dashboard .dashboard__wandabox .vflow .wandaholder {
        display: block;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        z-index: 3; }
      @media only screen and (min-width: 0px) and (max-width: 599px) {
        .standard-styles .dashboard .dashboard__wandabox .vflow .hide-on-mobile {
          display: none; } }
      @media only screen and (min-width: 600px) and (max-width: 999px) {
        .standard-styles .dashboard .dashboard__wandabox .vflow .hide-on-mobile {
          display: none; } }
      @media only screen and (min-width: 1000px) {
        .standard-styles .dashboard .dashboard__wandabox .vflow {
          justify-content: flex-start;
          margin-top: auto;
          align-items: flex-start; }
          .standard-styles .dashboard .dashboard__wandabox .vflow h1 {
            text-align: left;
            margin-bottom: 40px; }
          .standard-styles .dashboard .dashboard__wandabox .vflow .wandaholder {
            display: block;
            margin-left: 0;
            margin-right: 0; } }
      .standard-styles .dashboard .dashboard__wandabox .vflow .plan-information {
        font-family: 'AvertaPE', 'sans-serif';
        font-size: 24px;
        font-weight: 300;
        line-height: 30px;
        letter-spacing: normal;
        margin-bottom: 40px;
        text-align: left; }
      .standard-styles .dashboard .dashboard__wandabox .vflow .plan-success-title {
        font-family: 'AvertaPE', 'sans-serif';
        font-size: 56px;
        font-weight: 600;
        line-height: 64px;
        letter-spacing: normal;
        margin: 0px auto;
        margin-bottom: 40px; }
        @media only screen and (min-width: 0px) and (max-width: 599px) {
          .standard-styles .dashboard .dashboard__wandabox .vflow .plan-success-title {
            font-family: 'AvertaPE', 'sans-serif';
            font-size: 26px;
            font-weight: 600;
            line-height: 28px;
            letter-spacing: normal; } }
      .standard-styles .dashboard .dashboard__wandabox .vflow .wish-container {
        width: 100%;
        text-align: center;
        margin-bottom: 40px; }
      .standard-styles .dashboard .dashboard__wandabox .vflow .wishRow {
        margin: 0px auto;
        width: 75%;
        font-family: 'AvertaPE', 'sans-serif';
        font-size: 26px;
        font-weight: 300;
        line-height: 32px;
        letter-spacing: normal;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        border: 1px solid black;
        border-width: 0px 1px 1px 1px;
        border-bottom-color: lightgray; }
        .standard-styles .dashboard .dashboard__wandabox .vflow .wishRow:first-child {
          border-top-width: 1px; }
        .standard-styles .dashboard .dashboard__wandabox .vflow .wishRow:last-child {
          border-bottom-color: black; }
        .standard-styles .dashboard .dashboard__wandabox .vflow .wishRow > span {
          display: flex;
          align-items: center;
          width: 50%;
          min-width: 200px;
          text-align: left;
          padding: 16px; }
          .standard-styles .dashboard .dashboard__wandabox .vflow .wishRow > span:first-child {
            flex-wrap: wrap;
            border: 0px solid lightgray;
            border-right-width: 1px; }
            .standard-styles .dashboard .dashboard__wandabox .vflow .wishRow > span:first-child a {
              font-size: 14px;
              margin-left: 16px; }
          .standard-styles .dashboard .dashboard__wandabox .vflow .wishRow > span img {
            margin-right: 16px; }
          .standard-styles .dashboard .dashboard__wandabox .vflow .wishRow > span .green-check-mark {
            width: 30px;
            flex-shrink: 0;
            flex-grow: 0; }
          .standard-styles .dashboard .dashboard__wandabox .vflow .wishRow > span .document-icon {
            width: 20px;
            flex-shrink: 0;
            flex-grow: 0; }
        .standard-styles .dashboard .dashboard__wandabox .vflow .wishRow .dashboard__document,
        .standard-styles .dashboard .dashboard__wandabox .vflow .wishRow .dashboard__check--enabled {
          margin-right: 16px; }
        @media only screen and (min-width: 0px) and (max-width: 599px) {
          .standard-styles .dashboard .dashboard__wandabox .vflow .wishRow {
            width: 100%;
            font-family: 'AvertaPE', 'sans-serif';
            font-size: 20px;
            font-weight: 300;
            line-height: 22px;
            letter-spacing: normal; }
            .standard-styles .dashboard .dashboard__wandabox .vflow .wishRow > span .green-check-mark {
              width: 20px; }
            .standard-styles .dashboard .dashboard__wandabox .vflow .wishRow > span .document-icon {
              width: 15px; }
            .standard-styles .dashboard .dashboard__wandabox .vflow .wishRow > span:first-child {
              width: 100%;
              flex-wrap: wrap;
              border-right-width: 0px;
              padding-bottom: 5px; }
            .standard-styles .dashboard .dashboard__wandabox .vflow .wishRow > span:last-child {
              width: 100%;
              margin-left: 42px;
              padding-top: 5px; } }
    .standard-styles .dashboard .dashboard__wandabox h1 {
      font-family: 'Tiempos Headline', 'serif';
      font-size: 24px;
      font-weight: 500;
      line-height: 1;
      letter-spacing: normal; }
      @media only screen and (min-width: 1000px) {
        .standard-styles .dashboard .dashboard__wandabox h1 {
          font-family: 'Tiempos Headline', 'serif';
          font-size: 56px;
          font-weight: 500;
          line-height: 1;
          letter-spacing: normal;
          text-align: left;
          padding: 20px 50px; } }
  .standard-styles .dashboard .dashboard__contentbox {
    padding-left: 24px; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .dashboard .dashboard__contentbox {
        width: 100%;
        max-width: 50%;
        max-height: 100%;
        flex: 2 1 0; } }
    .standard-styles .dashboard .dashboard__contentbox .dashboard__contentbox__item {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: center;
      align-items: flex-start;
      align-content: flex-start; }
      @media only screen and (min-width: 1000px) {
        .standard-styles .dashboard .dashboard__contentbox .dashboard__contentbox__item {
          justify-content: space-between; } }
      .standard-styles .dashboard .dashboard__contentbox .dashboard__contentbox__item div:nth-child(2) {
        width: 248px;
        padding: 0 15px;
        flex-grow: 2; }
        @media only screen and (min-width: 1000px) {
          .standard-styles .dashboard .dashboard__contentbox .dashboard__contentbox__item div:nth-child(2) {
            width: 100%; } }
        .standard-styles .dashboard .dashboard__contentbox .dashboard__contentbox__item div:nth-child(2) p {
          margin-top: 0;
          margin-bottom: 0;
          font-weight: normal; }
          @media only screen and (min-width: 0px) and (max-width: 599px) {
            .standard-styles .dashboard .dashboard__contentbox .dashboard__contentbox__item div:nth-child(2) p {
              font-family: 'AvertaPE', 'sans-serif';
              font-size: 16px;
              font-weight: normal;
              line-height: 20px;
              letter-spacing: normal; } }
          @media only screen and (min-width: 600px) and (max-width: 999px) {
            .standard-styles .dashboard .dashboard__contentbox .dashboard__contentbox__item div:nth-child(2) p {
              font-family: 'AvertaPE', 'sans-serif';
              font-size: 16px;
              font-weight: normal;
              line-height: 20px;
              letter-spacing: normal; } }
          .standard-styles .dashboard .dashboard__contentbox .dashboard__contentbox__item div:nth-child(2) p:first-child {
            margin-top: 0;
            padding-top: 0;
            padding-bottom: 4px;
            font-weight: bold; }
            @media only screen and (min-width: 1000px) {
              .standard-styles .dashboard .dashboard__contentbox .dashboard__contentbox__item div:nth-child(2) p:first-child {
                padding-bottom: 8px; } }
      .standard-styles .dashboard .dashboard__contentbox .dashboard__contentbox__item .description--desktop {
        display: none;
        font-weight: normal; }
        @media only screen and (min-width: 1000px) {
          .standard-styles .dashboard .dashboard__contentbox .dashboard__contentbox__item .description--desktop {
            display: block; } }
      .standard-styles .dashboard .dashboard__contentbox .dashboard__contentbox__item .description--mobile {
        display: block;
        font-weight: normal; }
        @media only screen and (min-width: 1000px) {
          .standard-styles .dashboard .dashboard__contentbox .dashboard__contentbox__item .description--mobile {
            display: none; } }
      .standard-styles .dashboard .dashboard__contentbox .dashboard__contentbox__item div:nth-child(3) {
        color: #4c84f5;
        text-transform: uppercase; }
    .standard-styles .dashboard .dashboard__contentbox .plainlink,
    .standard-styles .dashboard .dashboard__contentbox .plainlink:link,
    .standard-styles .dashboard .dashboard__contentbox .plainlink:visited,
    .standard-styles .dashboard .dashboard__contentbox .plainlink:focus,
    .standard-styles .dashboard .dashboard__contentbox .plainlink:hover,
    .standard-styles .dashboard .dashboard__contentbox .plainlink:active {
      color: #000000 !important;
      text-decoration: none !important;
      text-transform: none !important;
      font-variant: none;
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 24px;
      font-weight: normal;
      line-height: 1;
      letter-spacing: normal; }
  .standard-styles .dashboard .dashboard__edit {
    display: block;
    background-size: cover; }
  .standard-styles .dashboard .dashboard__edit--done {
    content: 'Edit';
    background-image: none;
    width: auto; }
  .standard-styles .dashboard .donemarker {
    min-width: 150px;
    min-height: max-content; }
  .standard-styles .dashboard::after {
    content: '';
    display: block;
    clear: both; }
