/*
  The styles declared in here should be very limited. Only
  add styles here if they apply to non-material components.
  Otherwise, you should use JSS.
*/
html.mui-enabled {
  height: 100%; }
  html.mui-enabled body, html.mui-enabled #react-root {
    height: 100%; }
  html.mui-enabled #react-root > div:not(.standard-styles) {
    height: 100%;
    display: flex;
    flex-direction: column; }
    html.mui-enabled #react-root > div:not(.standard-styles) > * {
      flex-shrink: 0; }

.mui-fixed {
  box-sizing: content-box; }
