.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .id-verification__card-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 0;
  min-height: 100%; }
  .standard-styles .id-verification__card-wrapper .id-verification__card {
    background-color: #262626;
    color: white;
    margin: 0;
    border-radius: 0; }
    @media only screen and (min-width: 0px) and (max-width: 999px) {
      .standard-styles .id-verification__card-wrapper .id-verification__card {
        height: unset;
        max-height: unset;
        min-height: 100%; } }
    @media only screen and (min-width: 1200px) {
      .standard-styles .id-verification__card-wrapper .id-verification__card {
        padding: 0; } }

.standard-styles .id-verification {
  width: 100%;
  min-height: 100%; }
  .standard-styles .id-verification .loading {
    background: center no-repeat #434343 url("../../../../../icons/svg/icon-loading.svg");
    background-size: 64px 64px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1; }
  .standard-styles .id-verification .not-current-step {
    display: none; }
    @media only screen and (min-width: 1200px) {
      .standard-styles .id-verification .not-current-step {
        display: block; } }
  .standard-styles .id-verification .capture-success {
    padding-top: 40px; }
    @media only screen and (min-width: 1200px) {
      .standard-styles .id-verification .capture-success {
        padding-top: 200px; } }
  .standard-styles .id-verification .captured-img {
    width: 300px; }
  .standard-styles .id-verification .intro {
    height: 100%; }
  .standard-styles .id-verification .verification__title {
    display: flex;
    align-items: center;
    text-align: center;
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 24px;
    font-weight: bold;
    line-height: 32px;
    letter-spacing: normal; }
    .standard-styles .id-verification .verification__title:before {
      content: '';
      background-image: url("../../../../../icons/svg/icon-id-capture.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      width: 17px;
      height: 30px;
      margin-right: 16px; }
      @media only screen and (min-width: 1200px) {
        .standard-styles .id-verification .verification__title:before {
          width: 26px;
          height: 45px;
          margin-right: 17px; } }
    @media only screen and (min-width: 1200px) {
      .standard-styles .id-verification .verification__title {
        font-family: 'AvertaPE', 'sans-serif';
        font-size: 36px;
        font-weight: bold;
        line-height: 44px;
        letter-spacing: normal; } }
  .standard-styles .id-verification .verification__description {
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 14px;
    font-weight: normal;
    line-height: 22px;
    letter-spacing: normal; }
    @media only screen and (min-width: 1200px) {
      .standard-styles .id-verification .verification__description {
        margin-top: 26px;
        font-family: 'AvertaPE', 'sans-serif';
        font-size: 24px;
        font-weight: normal;
        line-height: 32px;
        letter-spacing: normal; } }
  @media only screen and (min-width: 1200px) {
    .standard-styles .id-verification {
      display: flex;
      flex-direction: column;
      background-color: #262626;
      height: 100%; }
      .standard-styles .id-verification.intro-step, .standard-styles .id-verification.camera-step {
        background-color: #111; }
      .standard-styles .id-verification div.intro {
        position: relative;
        width: 50%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end; }
      .standard-styles .id-verification div.camera {
        position: relative;
        width: 50%;
        height: auto; }
      .standard-styles .id-verification div.info {
        position: relative;
        width: 100%; } }
  .standard-styles .id-verification.fail-step {
    background: transparent; }
  .standard-styles .id-verification.manual-step {
    padding: 96px 80px;
    display: block;
    height: auto; }
    .standard-styles .id-verification.manual-step h1 {
      margin: 0 0 30px; }
    .standard-styles .id-verification.manual-step p {
      margin: 0 0 20px; }
    .standard-styles .id-verification.manual-step input[type='file'] {
      display: none; }
    .standard-styles .id-verification.manual-step .image-uploader-wrapper {
      display: flex;
      flex-wrap: wrap; }
    .standard-styles .id-verification.manual-step .image-uploader {
      width: 240px;
      margin: 20px 60px 10px 0; }
      .standard-styles .id-verification.manual-step .image-uploader:last-child {
        margin-right: 0; }
      .standard-styles .id-verification.manual-step .image-uploader .image-uploader__placeholder {
        width: 100%;
        height: 160px;
        background-image: url("../../../../../icons/svg/icon-camera-alt-material.svg");
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 6px;
        background-color: #505050; }
      .standard-styles .id-verification.manual-step .image-uploader img {
        width: 100%; }
      .standard-styles .id-verification.manual-step .image-uploader p {
        text-align: center;
        margin: 8px 0 24px;
        text-transform: uppercase;
        font-family: 'AvertaPE', 'sans-serif';
        font-size: 24px;
        font-weight: bold;
        line-height: 32px;
        letter-spacing: normal; }
      .standard-styles .id-verification.manual-step .image-uploader .image-uploader__save-button {
        width: 100%;
        margin: 0; }
    .standard-styles .id-verification.manual-step .verification__save-button {
      width: 200px;
      margin: 100px 0 0 0; }
