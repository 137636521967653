.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

@keyframes toggle-slide {
  0%,
  100% {
    transform: translateY(0px); }
  50% {
    transform: translateY(60px); } }

.standard-styles .loading-animation {
  text-align: center;
  width: 100%;
  opacity: 0;
  transition: opacity 1000ms ease-in-out; }
  .standard-styles .loading-animation.active {
    opacity: 1; }
  .standard-styles .loading-animation .bars {
    margin-bottom: 32px;
    text-align: center; }
    .standard-styles .loading-animation .bars > div {
      height: 130px;
      width: 12px;
      border: 2px solid #c5cdd5;
      border-radius: 2px;
      background-color: #f4f5f7;
      display: inline-block;
      margin: 0 35px;
      position: relative; }
      @media only screen and (min-width: 600px) and (max-width: 999px) {
        .standard-styles .loading-animation .bars > div {
          width: 10px;
          margin: 0 25px; } }
      @media only screen and (min-width: 0px) and (max-width: 599px) {
        .standard-styles .loading-animation .bars > div {
          width: 8px;
          margin: 0 15px; } }
      .standard-styles .loading-animation .bars > div:before {
        content: '';
        display: block;
        border-radius: 50%;
        border: 5px solid #769efb;
        background: #fff;
        height: 40px;
        width: 40px;
        position: absolute;
        left: 50%;
        margin-left: -20px;
        animation-name: toggle-slide;
        animation-duration: 4s;
        animation-iteration-count: infinite;
        margin-top: 10px;
        margin-bottom: 10px;
        box-sizing: border-box; }
        @media only screen and (min-width: 0px) and (max-width: 599px) {
          .standard-styles .loading-animation .bars > div:before {
            height: 24px;
            width: 24px;
            margin-left: -12px; } }
      .standard-styles .loading-animation .bars > div:nth-child(1):before {
        animation-delay: 0s; }
      .standard-styles .loading-animation .bars > div:nth-child(2):before {
        border-color: #1bc092;
        animation-delay: -2s;
        animation-timing-function: ease-in-out; }
      .standard-styles .loading-animation .bars > div:nth-child(3):before {
        border-color: #4eb0be;
        animation-delay: 0s;
        animation-duration: 3.4s; }
      .standard-styles .loading-animation .bars > div:nth-child(4):before {
        border-color: #a6db77;
        animation-delay: -2s; }
  .standard-styles .loading-animation h3 {
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    color: #414042;
    transition: opacity 500ms ease-in-out;
    opacity: 0; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .loading-animation h3 {
        font-size: 48px; } }
    .standard-styles .loading-animation h3.active {
      opacity: 1; }
  .standard-styles .loading-animation .success-icon {
    display: inline-block;
    width: 100%;
    max-width: 500px;
    height: 134px;
    object-fit: contain; }
