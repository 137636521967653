.standard-styles .chips .chip .chip__edit a,
.standard-styles .chips .chip .chip__edit a:link,
.standard-styles .chips .chip .chip__edit a:visited,
.standard-styles .chips .chip .chip__edit a:focus,
.standard-styles .chips .chip .chip__edit a:hover,
.standard-styles .chips .chip .chip__edit a:active {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: normal;
  font-variant: small-caps; }

.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .chips .sublabel, .standard-styles .chips .chips__context, .standard-styles .downshift-menu .heading {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: normal;
  text-transform: uppercase; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .chips .chip .chip__edit a,
.standard-styles .chips .chip .chip__edit a:link,
.standard-styles .chips .chip .chip__edit a:visited,
.standard-styles .chips .chip .chip__edit a:focus,
.standard-styles .chips .chip .chip__edit a:hover,
.standard-styles .chips .chip .chip__edit a:active {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: normal;
  font-variant: small-caps; }

.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .chips .sublabel, .standard-styles .chips .chips__context, .standard-styles .downshift-menu .heading {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: normal;
  text-transform: uppercase; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .chip-sentence-wrapper--bordered, .standard-styles .chips {
  border-bottom: dotted 6px #9ac5fe; }

.standard-styles .chips .sublabel {
  color: #4c84f5; }

.standard-styles .chips .chip .chip__edit, .standard-styles .downshift-menu {
  border-radius: 3px;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26); }

.standard-styles .chip-sentence-wrapper {
  display: inline;
  padding-top: 24px;
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 32px;
  font-weight: 300;
  line-height: 48px;
  letter-spacing: normal; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .chip-sentence-wrapper {
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 48px;
      font-weight: 300;
      line-height: 100px;
      letter-spacing: normal; } }
  .standard-styles .chip-sentence-wrapper:first-child {
    margin-right: 6px; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .chip-sentence-wrapper:first-child {
        margin-right: 16px; } }

.standard-styles .chip-sentence-wrapper--blue {
  color: #4c84f5;
  word-break: break-word;
  cursor: pointer; }

.standard-styles .chip-sentence-wrapper--bordered {
  border-bottom-style: solid;
  border-bottom-width: 2px;
  padding-top: 12px; }

.standard-styles .chips {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  margin-left: 6px;
  margin-top: 12px;
  max-height: 64px;
  overflow: visible;
  position: relative;
  padding-bottom: 8px;
  margin-bottom: 16px; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .chips {
      margin-top: unset; } }
  .standard-styles .chips .sublabel {
    position: absolute;
    top: calc(100% + 12px); }
  .standard-styles .chips:last-child {
    margin-right: 8px; }
  .standard-styles .chips .chips__context {
    position: absolute;
    top: calc(100% + 12px);
    color: #4c84f5; }
  .standard-styles .chips .chip {
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 18px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: normal;
    display: inline;
    background-color: #ffffff;
    width: max-content;
    padding: 10px 10px 10px 20px;
    color: #4c84f5;
    border-radius: 100px;
    border: solid 3px #4c84f5;
    margin-right: 8px;
    overflow: visible;
    position: relative; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .chips .chip {
        font-family: 'AvertaPE', 'sans-serif';
        font-size: 24px;
        font-weight: bold;
        line-height: 1;
        letter-spacing: normal; } }
    .standard-styles .chips .chip .chip__text {
      margin-right: 16px; }
      @media only screen and (min-width: 1000px) {
        .standard-styles .chips .chip .chip__text {
          margin-right: 16px; } }
    .standard-styles .chips .chip .chip__close {
      background-image: url("../../../icons/svg/icon-cancel.svg");
      background-size: 18px;
      cursor: pointer;
      display: inline-block;
      width: 18px;
      height: 18px;
      margin-bottom: -2px; }
      @media only screen and (min-width: 1000px) {
        .standard-styles .chips .chip .chip__close {
          background-size: 24px;
          width: 24px;
          height: 24px;
          margin-bottom: -4px; } }
    .standard-styles .chips .chip .chip__edit {
      position: fixed;
      display: block;
      top: 40%;
      left: calc(50% + 8px);
      width: 400px;
      max-width: 100%;
      margin-left: -200px;
      margin-right: auto;
      background-color: #f2f2f2;
      padding: 16px;
      z-index: 30;
      color: #000000; }
      @media only screen and (min-width: 0px) and (max-width: 599px) {
        .standard-styles .chips .chip .chip__edit {
          margin-left: -50%; } }
      .standard-styles .chips .chip .chip__edit a,
      .standard-styles .chips .chip .chip__edit a:link,
      .standard-styles .chips .chip .chip__edit a:visited,
      .standard-styles .chips .chip .chip__edit a:focus,
      .standard-styles .chips .chip .chip__edit a:hover,
      .standard-styles .chips .chip .chip__edit a:active {
        color: #4c84f5;
        text-decoration: none;
        text-transform: uppercase; }
      .standard-styles .chips .chip .chip__edit a:hover {
        text-decoration: underline; }
      .standard-styles .chips .chip .chip__edit a {
        font-family: 'AvertaPE', sans-serif !important;
        text-transform: none !important;
        font-variant: none !important;
        font-size: unset !important;
        font-weight: normal !important; }
      @media only screen and (min-width: 1000px) {
        .standard-styles .chips .chip .chip__edit {
          position: absolute;
          top: calc(100% + 24px);
          left: unset;
          min-height: max-content;
          margin-left: -20px;
          max-width: 600px; } }
      .standard-styles .chips .chip .chip__edit .edit__label {
        color: #000000; }
      .standard-styles .chips .chip .chip__edit .edit__stack {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: center;
        align-content: center;
        margin-top: 32px; }
        .standard-styles .chips .chip .chip__edit .edit__stack .button {
          width: 100%; }

.standard-styles .downshift-menu {
  position: absolute;
  top: calc(100% + 32px);
  z-index: 26;
  background-color: #ffffff;
  width: max-content;
  max-width: 100%;
  overflow-y: auto;
  max-height: 260px; }
  .standard-styles .downshift-menu .heading {
    text-transform: uppercase;
    padding: 12px; }
  .standard-styles .downshift-menu ul {
    list-style-type: none;
    margin: 0 0 12px 0;
    width: 100%;
    padding: 0; }
    .standard-styles .downshift-menu ul li {
      list-style-type: none;
      padding: 12px 112px 12px 16px;
      width: 100%;
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 24px;
      font-weight: normal;
      line-height: 28px;
      letter-spacing: normal; }
      .standard-styles .downshift-menu ul li.noicon {
        padding-right: 12px; }
      .standard-styles .downshift-menu ul li.selected {
        background-color: #f2f2f2; }
        .standard-styles .downshift-menu ul li.selected:after {
          content: url("../../../icons/svg/icon-select-text.svg");
          width: 32px;
          height: 24px;
          position: absolute;
          left: calc(100% - 64px); }
      .standard-styles .downshift-menu ul li.selected--noicon:after {
        content: none; }

.standard-styles .downshift-menu--unrestrained {
  max-width: max-content; }

.standard-styles .chips--solid-border {
  border-bottom: solid 2px #4c84f5; }

.standard-styles .chips--no-border {
  border: none !important;
  margin-bottom: 40px; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .chips--no-border {
      margin-bottom: unset; } }

.standard-styles .chips--right-pad {
  margin-right: 16px; }

.standard-styles .select-input {
  max-width: max-content;
  height: 56px;
  display: inline-block;
  padding-left: 8px;
  padding-right: 16px;
  z-index: 0;
  margin-right: 8px;
  border: 2px solid #d2d2d2;
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 24px;
  font-weight: normal;
  line-height: 1;
  letter-spacing: normal;
  -webkit-appearance: none;
  -webkit-border-radius: 0;
  background: #ffffff url("../../../icons/icon-expand.png") no-repeat 100% 50%; }
  .standard-styles .select-input:hover {
    border: 2px solid #888888; }
  .standard-styles .select-input:disabled {
    background-color: #d2d2d2; }
  .standard-styles .select-input:focus {
    outline: #4c84f5 solid 2px; }

.standard-styles .select-input--error {
  border: 2px solid #ff5a5a; }

.standard-styles option {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 24px;
  font-weight: normal;
  line-height: 1;
  letter-spacing: normal;
  color: #000000; }

.standard-styles .select-input--chippy {
  color: #4c84f5;
  border: unset;
  background: #ffffff url("../../../icons/icon-expand-blue.png") no-repeat top 60% right 0;
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 32px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: normal;
  padding: 0 32px 0 0;
  margin-right: 12px; }
  .standard-styles .select-input--chippy:hover {
    border: unset; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .select-input--chippy {
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 48px;
      font-weight: 300;
      line-height: 1;
      letter-spacing: normal; } }
