.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .session-list {
  height: 100%; }
  .standard-styles .session-list .left {
    float: left;
    overflow: auto;
    height: 100%;
    width: 477px;
    border-right: solid 1px #d2d2d2; }
    .standard-styles .session-list .left .left__header {
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 24px;
      font-weight: normal;
      line-height: 30px;
      letter-spacing: normal;
      margin: 40px; }
    .standard-styles .session-list .left .left__list .list__item {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      padding: 16px 24px;
      border-bottom: solid 1px #f5f3f3; }
      .standard-styles .session-list .left .left__list .list__item:first-child {
        border-top: solid 1px #f5f3f3; }
      .standard-styles .session-list .left .left__list .list__item.selected {
        background-color: #f0f3f9; }
      .standard-styles .session-list .left .left__list .list__item .name {
        font-family: 'AvertaPE', 'sans-serif';
        font-size: 16px;
        font-weight: normal;
        line-height: 24px;
        letter-spacing: normal;
        margin: 0;
        padding: 0; }
      .standard-styles .session-list .left .left__list .list__item .details {
        font-family: 'AvertaPE', 'sans-serif';
        font-size: 14px;
        font-weight: normal;
        line-height: 22px;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.54);
        margin: 0;
        padding: 0; }
      .standard-styles .session-list .left .left__list .list__item .open-icon:after {
        content: url("../../icons/svg/icon-arrow-right.svg"); }
  .standard-styles .session-list .right {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    float: right;
    text-align: center;
    height: 100%;
    width: calc(100% - 477px); }
    .standard-styles .session-list .right h4,
    .standard-styles .session-list .right p {
      margin: 0;
      padding: 0; }
    .standard-styles .session-list .right .item {
      margin-bottom: 24px;
      align-self: center; }
    .standard-styles .session-list .right .hourglass {
      height: 60px; }
      .standard-styles .session-list .right .hourglass:after {
        content: url("../../icons/svg/icon-hourglass.svg"); }
    .standard-styles .session-list .right .camera {
      height: 36px; }
      .standard-styles .session-list .right .camera:after {
        content: url("../../icons/svg/icon-video-camera.svg"); }
    .standard-styles .session-list .right .button {
      align-self: flex-end;
      width: 100%;
      margin: 0; }
