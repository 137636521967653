.standard-styles .portal__wrapper {
  position: absolute;
  z-index: 1; }

.standard-styles .portal__position--left {
  left: 1px; }

.standard-styles .portal__position--right {
  right: 1px; }

.standard-styles .portal__position--middle-small {
  left: calc(50% - 143px); }

.standard-styles .portal__position--middle-large {
  left: calc(50% - 160px); }
