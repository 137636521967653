.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .signature-example .label, .standard-styles .signature-example .amalfi, .standard-styles .signature-example .ballpoint, .standard-styles .signature-example .stylededit, .standard-styles .signature-example .whiteoleander {
  color: #000000;
  display: inline-block;
  margin-bottom: -24px; }

.standard-styles .signature-example {
  border-bottom: 2px dashed #7c7c7c;
  overflow-y: visible;
  min-width: 120px;
  font-size: 36px;
  position: relative;
  text-align: left;
  display: inline-block;
  margin-right: 40px;
  margin-bottom: 64px; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .signature-example {
      font-size: 72px; } }
  .standard-styles .signature-example .initial-marker {
    color: #7c7c7c;
    margin-right: 8px;
    position: relative;
    top: 8px;
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 24px;
    font-weight: normal;
    line-height: 30px;
    letter-spacing: normal; }
  .standard-styles .signature-example .label {
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 24px;
    font-weight: normal;
    line-height: 30px;
    letter-spacing: normal;
    position: absolute;
    top: 45px;
    margin-bottom: unset; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .signature-example .label {
        top: 90px; } }

.standard-styles .small-check-text {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 14px;
  font-weight: 300;
  line-height: 22px;
  letter-spacing: normal;
  text-align: left !important; }

.standard-styles .signature-example-flow {
  display: flex;
  flex-direction: column; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .signature-example-flow {
      flex-direction: row; } }

.standard-styles .signature-selection .amalfi,
.standard-styles .signature-selection .ballpoint,
.standard-styles .signature-selection .stylededit,
.standard-styles .signature-selection .whiteoleander {
  font-size: 72px;
  margin: 0 0 32px 0; }

.standard-styles .button--space {
  margin-top: 10px; }
