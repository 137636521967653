.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .document-image-viewer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  width: 100%;
  min-height: 100%; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .document-image-viewer {
      padding: 48px 0; } }
  .standard-styles .document-image-viewer img {
    display: block;
    max-width: 100%;
    margin: 0 auto; }
  .standard-styles .document-image-viewer .hidden {
    display: none; }
  .standard-styles .document-image-viewer h1 {
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 28px;
    font-weight: normal;
    line-height: 36px;
    letter-spacing: normal;
    text-align: center;
    display: block;
    padding-top: 10%; }
    .standard-styles .document-image-viewer h1:before {
      display: block;
      content: url("../../../icons/svg/icon-hourglass.svg"); }
