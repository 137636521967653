.standard-styles .checkout-container.checkout-container--subscription .order-summary .order-summary__body .body__content .body__highlight {
  color: #414042;
  font-weight: 600; }

.standard-styles .checkout-container.checkout-container--subscription .order-summary .order-summary__body .body__content.body__content--notice {
  font-size: 16px;
  line-height: 26px;
  padding-right: 20px; }

.standard-styles .checkout-container.checkout-container--subscription .button--paybutton {
  margin-top: 24px; }
