.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles #review__id .modal__content {
  width: max-content;
  max-width: 335px;
  overflow-y: auto;
  cursor: default;
  max-height: 230px;
  background-color: #ffffff;
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  border-radius: 3px;
  margin-top: 10px; }
  @media only screen and (min-width: 0px) and (max-width: 599px) {
    .standard-styles #review__id .modal__content {
      width: 286px; } }
  .standard-styles #review__id .modal__content .content__items {
    list-style-type: none;
    width: 100%;
    padding: 12px 0; }
    .standard-styles #review__id .modal__content .content__items .content__item {
      list-style-type: none;
      width: 100%;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #4d4d4e;
      padding: 16px 24px;
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 15px;
      font-weight: normal;
      line-height: 1;
      letter-spacing: normal; }
      .standard-styles #review__id .modal__content .content__items .content__item:hover {
        background-color: #f2f2f2;
        cursor: pointer; }
