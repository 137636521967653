@media only screen and (min-width: 0px) and (max-width: 599px) {
  .standard-styles .sign .review, .standard-styles .sign .actions {
    margin: 0 8px 16px 8px;
    padding: 6px 16px; } }

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .standard-styles .sign .review, .standard-styles .sign .actions {
    margin: 0 16px 16px 16px;
    padding: 21px 24px; } }

@media only screen and (min-width: 1000px) {
  .standard-styles .sign .review, .standard-styles .sign .actions {
    margin: 0 96px 32px 96px;
    padding: 16px 80px 80px 80px; } }

.standard-styles .nav-review .nav-review__unlock {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: normal;
  font-variant: small-caps; }

.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

@media only screen and (min-width: 0px) and (max-width: 599px) {
  .standard-styles .sign .review, .standard-styles .sign .actions {
    margin: 0 8px 16px 8px;
    padding: 6px 16px; } }

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .standard-styles .sign .review, .standard-styles .sign .actions {
    margin: 0 16px 16px 16px;
    padding: 21px 24px; } }

@media only screen and (min-width: 1000px) {
  .standard-styles .sign .review, .standard-styles .sign .actions {
    margin: 0 96px 32px 96px;
    padding: 16px 80px 80px 80px; } }

@media only screen and (min-width: 1000px) {
  .standard-styles .no-pad {
    padding: 0 !important; } }

.standard-styles .sign-loader {
  padding-top: calc(30vh - 120px); }
  @media only screen and (min-width: 1000px) {
    .standard-styles .sign-loader {
      padding-top: calc(30vh - 180px);
      padding-bottom: 60px; } }

.standard-styles .dep-by-default,
.standard-styles .not-dep-by-default {
  height: 100%; }

.standard-styles .sign {
  height: 100%;
  display: flex;
  flex-direction: column; }
  .standard-styles .sign .nav-review__button {
    cursor: pointer;
    position: fixed;
    top: 176px;
    right: -10px;
    width: 48px;
    height: 48px;
    background: #ffffff;
    border-radius: 100%;
    box-shadow: 0 3px 4px 0 #d9d9d9;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding-left: 10px;
    z-index: 300; }
    .standard-styles .sign .nav-review__button span {
      background-color: #d2d2d2;
      display: block;
      margin: 1.5px 0; }
      .standard-styles .sign .nav-review__button span.long {
        width: 22px;
        height: 3px; }
      .standard-styles .sign .nav-review__button span.short {
        width: 17px;
        height: 3px; }
    @media only screen and (min-width: 1120px) {
      .standard-styles .sign .nav-review__button {
        display: none; } }
  .standard-styles .sign .review {
    margin: 0;
    flex: 1;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    padding-right: 40px;
    padding-bottom: 40px;
    z-index: 1; }
    @media only screen and (min-width: 0px) and (max-width: 599px) {
      .standard-styles .sign .review {
        padding-right: 16px; } }
    @media only screen and (min-width: 600px) and (max-width: 999px) {
      .standard-styles .sign .review {
        padding-right: 16px; } }
    @media only screen and (min-width: 1120px) {
      .standard-styles .sign .review {
        padding-bottom: 80px;
        width: calc(100% - 318px); } }
    .standard-styles .sign .review .docs {
      display: block;
      overflow: auto; }
      @media only screen and (min-width: 1120px) {
        .standard-styles .sign .review .docs {
          margin: 0 !important; } }
    .standard-styles .sign .review.review__info {
      width: inherit; }
  .standard-styles .sign .actions {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    z-index: 3;
    margin: 0;
    box-shadow: 0 -3px 5px 0 rgba(210, 210, 210, 0.25);
    background-color: white;
    padding: 24px 40px 24px 80px;
    /* these links are for downloading documents after payment */ }
    .standard-styles .sign .actions.unpaid__actions a {
      text-decoration: none; }
    .standard-styles .sign .actions .final-review-controls {
      display: flex;
      flex-flow: wrap; }
      .standard-styles .sign .actions .final-review-controls a {
        margin-right: 20px;
        padding: 10px 20px 10px 20px;
        border: 1px solid #4c84f5; }
    @media only screen and (min-width: 0px) and (max-width: 599px) {
      .standard-styles .sign .actions {
        padding: 0 24px; }
        .standard-styles .sign .actions.unpaid__actions {
          padding: 16px 24px; }
        .standard-styles .sign .actions.paid__actions {
          padding: 12px 24px;
          justify-content: center; }
        .standard-styles .sign .actions.review__final {
          padding: 12px 24px; }
        .standard-styles .sign .actions .check {
          font-size: 16px !important;
          margin-bottom: 12px; }
          .standard-styles .sign .actions .check .check__label {
            margin-bottom: 0; } }
    @media only screen and (min-width: 600px) and (max-width: 999px) {
      .standard-styles .sign .actions .check {
        width: 100%;
        font-size: 16px !important;
        margin-bottom: 12px; }
        .standard-styles .sign .actions .check .check__label {
          font-size: 16px;
          margin-bottom: 0; }
      .standard-styles .sign .actions.unpaid__actions .center {
        display: flex;
        flex-direction: column; }
      .standard-styles .sign .actions.paid__actions {
        flex-direction: row; }
      .standard-styles .sign .actions.review__final {
        flex-direction: row;
        padding-left: 60px; }
        .standard-styles .sign .actions.review__final .pdfcontrols {
          min-width: 46%; }
      .standard-styles .sign .actions .check__indicator {
        margin-left: 0; } }
    @media only screen and (min-width: 1000px) {
      .standard-styles .sign .actions {
        flex-direction: row; }
        .standard-styles .sign .actions .check {
          width: max-content; } }
    @media only screen and (min-width: 1000px) and (-ms-high-contrast: active) {
      .standard-styles .sign .actions .check {
        width: 80%; } }
    @media only screen and (min-width: 1000px) {
        .standard-styles .sign .actions.review__final .pdfcontrols {
          min-width: 45%; }
        .standard-styles .sign .actions .check .check__label {
          font-size: 16px; } }
    @media only screen and (min-width: 1200px) {
      .standard-styles .sign .actions .check {
        width: max-content; } }
  @media only screen and (min-width: 1200px) and (-ms-high-contrast: active) {
    .standard-styles .sign .actions .check {
      width: 59%; } }
    @media only screen and (min-width: 1120px) {
      .standard-styles .sign .actions {
        width: calc(100% - 318px);
        flex-direction: row;
        justify-content: space-between;
        padding-right: 40px;
        padding-left: 80px;
        padding-top: 16px; } }
    .standard-styles .sign .actions .button__review {
      font-weight: normal; }
      .standard-styles .sign .actions .button__review:hover {
        background-color: #55a0ff;
        text-decoration: none; }
      .standard-styles .sign .actions .button__review:disabled {
        background-color: #d2d2d2; }
    .standard-styles .sign .actions .button__review--tertiary {
      color: #55a0ff;
      font-weight: normal; }
    .standard-styles .sign .actions .button__unpay {
      width: 224px; }
      @media only screen and (min-width: 0px) and (max-width: 599px) {
        .standard-styles .sign .actions .button__unpay {
          width: 100%; } }
    .standard-styles .sign .actions .button__pay {
      width: 218px;
      margin: 0; }
      @media only screen and (min-width: 1120px) {
        .standard-styles .sign .actions .button__pay {
          width: 218px;
          margin-right: 10px; } }
    .standard-styles .sign .actions .pdfcontrols {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: center;
      align-content: center; }
      @media only screen and (min-width: 1120px) {
        .standard-styles .sign .actions .pdfcontrols {
          display: flex; } }
      .standard-styles .sign .actions .pdfcontrols span {
        display: inline-block; }
      .standard-styles .sign .actions .pdfcontrols span.paid {
        color: #55a0ff;
        font-weight: normal;
        cursor: pointer !important;
        margin-right: 16px; }
        .standard-styles .sign .actions .pdfcontrols span.paid:hover {
          text-decoration: none; }
    .standard-styles .sign .actions .pdfcontrols--mobile {
      display: flex;
      padding-top: 16px; }
      @media only screen and (min-width: 1120px) {
        .standard-styles .sign .actions .pdfcontrols--mobile {
          padding-top: 0; } }
    .standard-styles .sign .actions.actions__info {
      width: inherit; }
      @media only screen and (min-width: 0px) and (max-width: 599px) {
        .standard-styles .sign .actions.actions__info {
          padding: 0 24px; } }
      @media only screen and (min-width: 600px) and (max-width: 999px) {
        .standard-styles .sign .actions.actions__info .check {
          justify-content: center; } }

.standard-styles .nav-review {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: fixed;
  right: -325px;
  width: 318px;
  overflow: auto;
  top: 0;
  background-color: #f7f7f7;
  height: 100%;
  z-index: 200;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.2);
  border-top-right-radius: 6px; }
  .standard-styles .nav-review.in {
    -webkit-animation: in 0.5s forwards;
    animation: in 0.5s forwards; }
  .standard-styles .nav-review.out {
    -webkit-animation: out 0.5s backwards;
    animation: out 0.5s backwards; }
  @media only screen and (min-width: 1120px) {
    .standard-styles .nav-review {
      top: auto;
      height: 100%;
      z-index: auto;
      overflow: auto;
      position: absolute;
      background-color: #ffffff;
      right: 0;
      margin-left: 16px;
      bottom: 0;
      border-left: 1px solid #d2d2d2;
      box-shadow: none; } }
  .standard-styles .nav-review .nav-review__contents {
    overflow: auto;
    flex: 1; }
    .standard-styles .dep-by-default .nav-review .nav-review__contents {
      padding: 0 12px; }
    @media only screen and (min-width: 1120px) {
      .standard-styles .nav-review .nav-review__contents {
        padding-top: 72px; }
        .standard-styles .dep-by-default .nav-review .nav-review__contents {
          padding: 0 20px; } }
  .standard-styles .nav-review .nav-review__wrapper {
    background-color: #f7f7f7; }
    .standard-styles .not-dep-by-default .nav-review .nav-review__wrapper {
      margin: 0 16px 24px;
      padding: 24px 0 24px 24px; }
    .standard-styles .dep-by-default .nav-review .nav-review__wrapper {
      padding: 7px 19px 24px 19px;
      margin-bottom: 24px; }
    @media only screen and (min-width: 0px) and (max-width: 599px) {
      .standard-styles .nav-review .nav-review__wrapper {
        padding-bottom: 0; } }
  .standard-styles .nav-review .nav-review__unlock {
    display: block;
    text-align: center;
    margin-right: 8px;
    padding: 16px; }
    .standard-styles .nav-review .nav-review__unlock:before {
      position: relative;
      top: 3px;
      left: -8px;
      content: url("../icons/svg/icon-unlocked.svg"); }
  .standard-styles .nav-review .nav-review__section {
    margin: 16px 0;
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 16px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: normal; }
    .standard-styles .nav-review .nav-review__section:first-child {
      margin-top: 0; }
    .standard-styles .nav-review .nav-review__section:last-child {
      margin-bottom: 0; }
    .standard-styles .nav-review .nav-review__section .section__text {
      font-size: 16px;
      line-height: 32px;
      margin: 0; }
      .standard-styles .nav-review .nav-review__section .section__text a {
        font-family: 'AvertaPE', 'sans-serif';
        font-size: 16px;
        font-weight: bold;
        line-height: 32px;
        letter-spacing: normal;
        color: black;
        text-transform: none;
        font-variant: unset; }
        .standard-styles .nav-review .nav-review__section .section__text a:hover {
          text-decoration: none; }
    .standard-styles .nav-review .nav-review__section .nav-review__document {
      display: flex;
      align-items: flex-start;
      margin-left: -4px;
      cursor: pointer; }
      .standard-styles .nav-review .nav-review__section .nav-review__document .document__lock {
        display: inline-block;
        width: 24px;
        height: 26px; }
        .standard-styles .dep-by-default .nav-review .nav-review__section .nav-review__document .document__lock {
          display: none; }
        .standard-styles .nav-review .nav-review__section .nav-review__document .document__lock:before {
          position: relative;
          top: 3px;
          content: url("../icons/svg/icon-lock.svg"); }
        .standard-styles .nav-review .nav-review__section .nav-review__document .document__lock.hidden {
          visibility: hidden; }
      .standard-styles .nav-review .nav-review__section .nav-review__document .document__text {
        margin-left: 10px;
        color: black;
        font-family: 'AvertaPE', 'sans-serif';
        font-size: 16px;
        font-weight: 400;
        line-height: 1;
        letter-spacing: normal;
        text-decoration: underline;
        text-transform: none;
        font-size: 16px;
        letter-spacing: 1px;
        font-style: italic;
        font-variant: none; }
        .standard-styles .nav-review .nav-review__section .nav-review__document .document__text:hover {
          text-decoration: underline; }
        .standard-styles .dep-by-default .nav-review .nav-review__section .nav-review__document .document__text {
          font-size: 15px;
          line-height: 1.3;
          padding: 4px 12px;
          border-radius: 4px; }
        .standard-styles .nav-review .nav-review__section .nav-review__document .document__text.active {
          color: #324a9a;
          font-weight: 500; }
          .standard-styles .dep-by-default .nav-review .nav-review__section .nav-review__document .document__text.active {
            color: #3c59ba;
            background-color: rgba(76, 132, 245, 0.33); }
  .standard-styles .nav-review .nav-review__title {
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 20px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: normal;
    margin: 0 16px;
    padding: 24px 0 24px 24px; }
    .standard-styles .dep-by-default .nav-review .nav-review__title {
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 21px;
      font-weight: bold;
      line-height: 1;
      letter-spacing: normal;
      margin: 21px 0 15px;
      padding: 0;
      color: black; }
  .standard-styles .nav-review .nav-review__footer {
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: normal;
    padding: 16px; }
    .standard-styles .nav-review .nav-review__footer.nav-review__footer--new {
      color: #525252; }
      .standard-styles .nav-review .nav-review__footer.nav-review__footer--new h5 {
        font-family: 'AvertaPE', 'sans-serif';
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        letter-spacing: normal;
        margin: 0;
        color: black; }
  .standard-styles .nav-review .review-action-buttons {
    display: none; }
    .standard-styles .not-dep-by-default .nav-review .review-action-buttons {
      padding: 0;
      display: block; }
    @media only screen and (min-width: 1120px) {
      .standard-styles .nav-review .review-action-buttons {
        display: flex; } }

.standard-styles .next-steps-container {
  display: block; }
  @media only screen and (min-width: 1120px) {
    .standard-styles .next-steps-container {
      display: none; } }
  .standard-styles .next-steps-container .next-steps-container__questions {
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 21px;
    font-weight: bold;
    line-height: 21px;
    letter-spacing: normal;
    margin: 0 0 16px; }
  .standard-styles .next-steps-container .next-steps-container__next-steps {
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 18px;
    font-weight: normal;
    line-height: 23px;
    letter-spacing: normal;
    margin: 0; }

.standard-styles .review-action-buttons {
  box-shadow: 0 -3px 5px 0 rgba(210, 210, 210, 0.25);
  display: flex;
  padding: 16px; }
  .standard-styles .not-dep-by-default .review-action-buttons > * {
    flex: 1 1 50%; }
  .standard-styles .dep-by-default .review-action-buttons {
    flex-direction: row;
    justify-content: center;
    padding: 20px 20px 12px; }
  @media only screen and (min-width: 1120px) {
    .standard-styles .review-action-buttons {
      display: none; } }
  .standard-styles .review-action-buttons .next-steps-container {
    display: none; }
    @media only screen and (min-width: 1120px) {
      .standard-styles .review-action-buttons .next-steps-container {
        display: block; } }

.standard-styles .document-buttons {
  display: flex;
  justify-content: space-between; }
  .standard-styles .document-buttons > div {
    cursor: pointer;
    padding: 4px;
    text-decoration: underline; }
    .standard-styles .document-buttons > div:focus {
      outline: 5px auto Highlight;
      outline: 5px auto -webkit-focus-ring-color; }
    .standard-styles .document-buttons > div > span {
      margin-right: 8px; }

@-webkit-keyframes in {
  .standard-styles from {
    right: -325px; }
  .standard-styles to {
    right: 0; } }

@keyframes in {
  from {
    right: -325px; }
  to {
    right: 0; } }

@-webkit-keyframes out {
  .standard-styles from {
    right: 0; }
  .standard-styles to {
    right: -325px; } }

@keyframes out {
  from {
    right: 0; }
  to {
    right: -325px; } }

@-webkit-keyframes blink {
  .standard-styles 50% {
    border-bottom: 0; } }

@keyframes blink {
  50% {
    border-bottom: none; } }

.standard-styles .credit-card-icon {
  width: 20px;
  height: 17px;
  margin-bottom: -2px;
  margin-right: 12px;
  object-fit: contain;
  display: inline-block;
  cursor: pointer !important;
  background-image: url("../icons/svg/icon-credit-card.svg");
  flex-shrink: 0; }

.standard-styles .printer-icon {
  width: 20px;
  height: 19px;
  margin-bottom: -2px;
  margin-right: 12px;
  object-fit: contain;
  display: inline-block;
  cursor: pointer !important;
  background-image: url("../icons/svg/icon-printer.svg");
  flex-shrink: 0; }

.standard-styles .email-icon {
  width: 20px;
  height: 17px;
  margin-bottom: -2px;
  margin-right: 12px;
  object-fit: contain;
  display: inline-block;
  cursor: pointer !important;
  background-image: url("../icons/svg/icon-email.svg");
  flex-shrink: 0; }

.standard-styles .download-icon {
  width: 14px;
  height: 18px;
  margin-bottom: -2px;
  margin-right: 12px;
  object-fit: contain;
  display: inline-block;
  cursor: pointer !important;
  background-image: url("../icons/svg/icon-download.svg");
  flex-shrink: 0; }

.standard-styles .clock-icon {
  width: 20px;
  height: 21px;
  margin-bottom: -2px;
  margin-right: 12px;
  object-fit: contain;
  display: inline-block;
  cursor: pointer !important;
  background-image: url("../icons/svg/icon-clock.svg");
  flex-shrink: 0; }
