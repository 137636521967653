.standard-styles .storage-wrapper .card--storage .right .right__bottom .bottom__icon-flow a,
.standard-styles .storage-wrapper .card--storage .right .right__bottom .bottom__icon-flow a:link,
.standard-styles .storage-wrapper .card--storage .right .right__bottom .bottom__icon-flow a:visited,
.standard-styles .storage-wrapper .card--storage .right .right__bottom .bottom__icon-flow a:focus,
.standard-styles .storage-wrapper .card--storage .right .right__bottom .bottom__icon-flow a:hover,
.standard-styles .storage-wrapper .card--storage .right .right__bottom .bottom__icon-flow a:active {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: normal;
  font-variant: small-caps; }

.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .mobile__item {
  display: none; }
  @media only screen and (min-width: 0px) and (max-width: 599px) {
    .standard-styles .mobile__item {
      display: block; } }
  @media only screen and (min-width: 600px) and (max-width: 999px) {
    .standard-styles .mobile__item {
      display: block; } }

.standard-styles .desktop__item {
  display: none; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .desktop__item {
      display: block; } }
  @media only screen and (min-width: 1200px) {
    .standard-styles .desktop__item {
      display: block; } }

.standard-styles .storage-wrapper {
  width: 100%;
  height: 100%; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .storage-wrapper {
      height: calc(100% - 100px); } }
  .standard-styles .storage-wrapper .card--storage {
    display: flex;
    flex-direction: column;
    padding: 0;
    position: static; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .storage-wrapper .card--storage {
        position: relative;
        flex-direction: row;
        margin-bottom: 0; } }
    .standard-styles .storage-wrapper .card--storage .left {
      display: none; }
      @media only screen and (min-width: 1000px) {
        .standard-styles .storage-wrapper .card--storage .left {
          display: inline-block;
          width: calc(100% - 318px); } }
      .standard-styles .storage-wrapper .card--storage .left .info-wrapper {
        display: block; }
        .standard-styles .storage-wrapper .card--storage .left .info-wrapper > div {
          width: 50%;
          display: inline-block; }
          .standard-styles .storage-wrapper .card--storage .left .info-wrapper > div span:first-child {
            font-family: 'AvertaPE', 'sans-serif';
            font-size: 20px;
            font-weight: bold;
            line-height: 30px;
            letter-spacing: normal; }
          .standard-styles .storage-wrapper .card--storage .left .info-wrapper > div span:last-child {
            font-family: 'AvertaPE', 'sans-serif';
            font-size: 20px;
            font-weight: normal;
            line-height: 30px;
            letter-spacing: normal; }
          .standard-styles .storage-wrapper .card--storage .left .info-wrapper > div > span {
            display: block; }
        .standard-styles .storage-wrapper .card--storage .left .info-wrapper div.icon-actions {
          width: 100%; }
    .standard-styles .storage-wrapper .card--storage .left--padded {
      padding: 72px 80px;
      overflow: auto; }
      .standard-styles .storage-wrapper .card--storage .left--padded > img {
        width: 100%; }
    .standard-styles .storage-wrapper .card--storage .right {
      text-align: left;
      display: flex;
      flex-direction: column; }
      @media only screen and (min-width: 0px) and (max-width: 599px) {
        .standard-styles .storage-wrapper .card--storage .right {
          flex: 1; } }
      @media only screen and (min-width: 600px) and (max-width: 999px) {
        .standard-styles .storage-wrapper .card--storage .right {
          flex: 1; } }
      @media only screen and (min-width: 1000px) {
        .standard-styles .storage-wrapper .card--storage .right {
          width: 318px;
          box-shadow: 0 2px 6px 0 rgba(87, 159, 253, 0.1); } }
      .standard-styles .storage-wrapper .card--storage .right .right__revocation-box {
        margin-top: 24px; }
        @media only screen and (min-width: 1000px) {
          .standard-styles .storage-wrapper .card--storage .right .right__revocation-box {
            margin-top: 40px; } }
        .standard-styles .storage-wrapper .card--storage .right .right__revocation-box .box__header {
          font-family: 'AvertaPE', 'sans-serif';
          font-size: 16px;
          font-weight: bold;
          line-height: 1;
          letter-spacing: normal;
          margin-bottom: 8px; }
        .standard-styles .storage-wrapper .card--storage .right .right__revocation-box .box__doc-info {
          font-family: 'AvertaPE', 'sans-serif';
          font-size: 16px;
          font-weight: 300;
          line-height: 1;
          letter-spacing: normal;
          margin: 0 0 4px 0; }
          .standard-styles .storage-wrapper .card--storage .right .right__revocation-box .box__doc-info .bold {
            font-weight: normal; }
      .standard-styles .storage-wrapper .card--storage .right .mobile__download-button {
        width: 282px;
        height: 80px;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.12);
        border-radius: 6px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin: 48px auto; }
        .standard-styles .storage-wrapper .card--storage .right .mobile__download-button > a {
          font-variant: normal; }
        @media only screen and (min-width: 1000px) {
          .standard-styles .storage-wrapper .card--storage .right .mobile__download-button {
            display: none; } }
        .standard-styles .storage-wrapper .card--storage .right .mobile__download-button .button__right-part {
          flex: 1;
          background-color: #4c84f5;
          background-image: url("../icons/svg/icon-file.svg");
          background-repeat: no-repeat;
          background-size: 30%;
          background-position: center;
          width: 100%;
          height: 100%;
          border-top-left-radius: 6px;
          border-bottom-left-radius: 6px; }
        .standard-styles .storage-wrapper .card--storage .right .mobile__download-button .button__left-part {
          flex: 2;
          background-color: #ffffff;
          color: #000000;
          text-align: left;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
          margin-left: 16px;
          position: relative;
          text-transform: none;
          font-family: 'AvertaPE', 'sans-serif';
          font-size: 16px;
          font-weight: normal;
          line-height: 20px;
          letter-spacing: normal; }
          .standard-styles .storage-wrapper .card--storage .right .mobile__download-button .button__left-part:after {
            content: url("../icons/svg/icon-download.svg");
            position: absolute;
            top: 10px;
            right: 15px; }
      .standard-styles .storage-wrapper .card--storage .right .right__top {
        padding: 24px;
        min-height: max-content;
        flex: 1; }
        @media only screen and (min-width: 1000px) {
          .standard-styles .storage-wrapper .card--storage .right .right__top {
            padding: 24px 24px 0 24px; } }
        .standard-styles .storage-wrapper .card--storage .right .right__top .info-wrapper {
          width: 100%;
          color: #000000;
          display: flex;
          flex-direction: column;
          font-family: 'AvertaPE', 'sans-serif';
          font-size: 20px;
          font-weight: normal;
          line-height: 30px;
          letter-spacing: normal; }
          .standard-styles .storage-wrapper .card--storage .right .right__top .info-wrapper > div {
            margin-top: 16px;
            min-height: 60px; }
            .standard-styles .storage-wrapper .card--storage .right .right__top .info-wrapper > div span {
              display: block; }
          .standard-styles .storage-wrapper .card--storage .right .right__top .info-wrapper .info-wrapper__header {
            font-weight: bold; }
          .standard-styles .storage-wrapper .card--storage .right .right__top .info-wrapper .info-wrapper__body {
            font-weight: normal; }
      .standard-styles .storage-wrapper .card--storage .right .right__close {
        position: absolute;
        top: 30px;
        left: 24px;
        width: 16px;
        height: 16px; }
        .standard-styles .storage-wrapper .card--storage .right .right__close:after {
          content: '';
          cursor: pointer;
          display: block;
          width: 16px;
          height: 16px;
          background-position: center;
          background-repeat: no-repeat;
          background-size: contain;
          background-image: url("../icons/svg/icon-close-mobile.svg"); }
        @media only screen and (min-width: 1000px) {
          .standard-styles .storage-wrapper .card--storage .right .right__close {
            right: 24px;
            position: absolute;
            left: unset; }
            .standard-styles .storage-wrapper .card--storage .right .right__close:after {
              content: '';
              background-image: url("../icons/svg/icon-close-transparent.svg");
              width: 16px;
              height: 16px; } }
      .standard-styles .storage-wrapper .card--storage .right .right__heading {
        font-family: 'AvertaPE', 'sans-serif';
        font-size: 20px;
        font-weight: 300;
        line-height: 30px;
        letter-spacing: normal;
        padding: 0;
        margin: 24px 0 8px 0; }
      .standard-styles .storage-wrapper .card--storage .right .right__list-header {
        font-family: 'AvertaPE', 'sans-serif';
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
        letter-spacing: normal;
        padding: 0;
        margin: 8px 0; }
      .standard-styles .storage-wrapper .card--storage .right .right__list {
        list-style-type: none;
        padding: 0;
        margin: 0; }
        .standard-styles .storage-wrapper .card--storage .right .right__list li {
          font-family: 'AvertaPE', 'sans-serif';
          font-size: 16px;
          font-weight: 300;
          line-height: 24px;
          letter-spacing: normal;
          font-style: italic; }
          .standard-styles .storage-wrapper .card--storage .right .right__list li .check {
            width: 16px;
            height: 16px;
            display: inline-block;
            background-image: url("../icons/svg/icon-check-green.svg");
            background-size: cover;
            margin-right: 8px;
            position: relative;
            top: 2px; }
      .standard-styles .storage-wrapper .card--storage .right .right__bottom {
        width: 100%;
        top: calc(100% - 171px);
        left: 0;
        box-shadow: 0 -3px 5px 0 rgba(210, 210, 210, 0.25);
        padding-top: 16px;
        margin: 0;
        text-align: left; }
        .standard-styles .storage-wrapper .card--storage .right .right__bottom > div {
          padding: 0 24px; }
        .standard-styles .storage-wrapper .card--storage .right .right__bottom .bottom__label {
          font-family: 'AvertaPE', 'sans-serif';
          font-size: 16px;
          font-weight: bold;
          line-height: 24px;
          letter-spacing: normal;
          margin: 0 0 16px 0; }
        .standard-styles .storage-wrapper .card--storage .right .right__bottom .bottom__mode-switch {
          font-family: 'AvertaPE', 'sans-serif';
          font-size: 12px;
          font-weight: bold;
          line-height: 16px;
          letter-spacing: 1px;
          margin: 0 0 16px 0;
          cursor: pointer;
          color: #4c84f5;
          text-transform: uppercase; }
        .standard-styles .storage-wrapper .card--storage .right .right__bottom .bottom__icon-flow {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          justify-content: flex-start;
          align-items: center;
          align-content: center;
          margin: 0 0 16px 0; }
          .standard-styles .storage-wrapper .card--storage .right .right__bottom .bottom__icon-flow a,
          .standard-styles .storage-wrapper .card--storage .right .right__bottom .bottom__icon-flow a:link,
          .standard-styles .storage-wrapper .card--storage .right .right__bottom .bottom__icon-flow a:visited,
          .standard-styles .storage-wrapper .card--storage .right .right__bottom .bottom__icon-flow a:focus,
          .standard-styles .storage-wrapper .card--storage .right .right__bottom .bottom__icon-flow a:hover,
          .standard-styles .storage-wrapper .card--storage .right .right__bottom .bottom__icon-flow a:active {
            color: #4c84f5;
            text-decoration: none;
            text-transform: uppercase; }
          .standard-styles .storage-wrapper .card--storage .right .right__bottom .bottom__icon-flow a:hover {
            text-decoration: underline; }
          .standard-styles .storage-wrapper .card--storage .right .right__bottom .bottom__icon-flow a {
            margin-right: 10px; }
        .standard-styles .storage-wrapper .card--storage .right .right__bottom .button {
          height: 48px;
          top: calc(100% - 48px);
          left: 0;
          padding: 16px 0;
          margin: 0;
          text-align: center;
          width: 100%;
          border-bottom-left-radius: 6px;
          border-bottom-right-radius: 6px; }
          @media only screen and (min-width: 1000px) {
            .standard-styles .storage-wrapper .card--storage .right .right__bottom .button {
              border-bottom-left-radius: 0; } }
    .standard-styles .storage-wrapper .card--storage .icon:before {
      display: inline-block;
      margin-right: 8px;
      position: relative;
      top: 4px; }
    .standard-styles .storage-wrapper .card--storage .icon--email:before {
      content: url("../icons/svg/icon-email.svg"); }
    .standard-styles .storage-wrapper .card--storage .icon--print:before {
      content: url("../icons/svg/icon-printer.svg"); }
    .standard-styles .storage-wrapper .card--storage .icon--download:before {
      content: url("../icons/svg/icon-download.svg"); }
    .standard-styles .storage-wrapper .card--storage .icon-actions {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin: 16px 0 24px 0; }
      .standard-styles .storage-wrapper .card--storage .icon-actions .icon:last-child {
        margin-left: 13px; }

.standard-styles .processing-video-wrapper {
  max-width: 640px; }

.standard-styles .preserve-aspect-ratio {
  height: 0;
  padding-bottom: 75%;
  position: relative;
  margin-bottom: 16px; }
  .standard-styles .preserve-aspect-ratio .processing-video {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #434343;
    color: white; }
    .standard-styles .preserve-aspect-ratio .processing-video h4 {
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 24px;
      font-weight: normal;
      line-height: 1;
      letter-spacing: normal;
      margin: 0 0 4px 0; }
    .standard-styles .preserve-aspect-ratio .processing-video p {
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 16px;
      font-weight: normal;
      line-height: 1;
      letter-spacing: normal;
      margin: 0; }
    .standard-styles .preserve-aspect-ratio .processing-video .processing-video__indicator {
      background: url("../icons/svg/icon-loading.svg");
      background-size: contain;
      height: 32px;
      width: 32px;
      margin-right: 16px; }

.standard-styles .video {
  max-width: 100%;
  margin-bottom: 16px; }
