.standard-styles .navbar__items li, .standard-styles .account-dropdown ul li {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: normal;
  font-variant: small-caps; }

.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.mui-enabled .nav-container {
  display: none !important; }

.standard-styles .navbar {
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center; }
  @media only screen and (min-width: 0px) and (max-width: 599px) {
    .standard-styles .navbar {
      display: none; } }
  @media only screen and (min-width: 600px) and (max-width: 999px) {
    .standard-styles .navbar {
      display: none; } }
  @media only screen and (min-width: 1000px) {
    .standard-styles .navbar {
      padding: 40px 96px;
      margin: 0; } }
  .standard-styles .navbar a,
  .standard-styles .navbar a:link,
  .standard-styles .navbar a:visited,
  .standard-styles .navbar a:focus,
  .standard-styles .navbar a:hover,
  .standard-styles .navbar a:active {
    color: #000000;
    text-decoration: none;
    text-transform: uppercase; }
  .standard-styles .navbar a:hover {
    text-decoration: underline; }
  .standard-styles .impersonating .navbar {
    background: rgba(255, 0, 0, 0.3); }

.standard-styles .navbar__left {
  margin-right: auto; }

.standard-styles .navbar__center {
  margin: auto; }

.standard-styles .navbar__right {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  align-content: center; }
  .standard-styles .navbar__right .right__item {
    margin-left: 32px; }

.standard-styles .contact-info {
  width: 330px;
  height: 16px;
  text-transform: uppercase;
  color: #000000;
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 12px;
  font-weight: normal;
  line-height: 1.33;
  letter-spacing: 1px; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .contact-info {
      text-align: right;
      display: block; } }

.standard-styles .navbar-info {
  height: 16px;
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 12px;
  font-weight: bold;
  line-height: 1.33;
  letter-spacing: 1px;
  color: #000000; }
  .standard-styles .navbar-info div:last-child {
    margin-left: 45px; }
  .standard-styles .navbar-info .mode-select {
    position: relative;
    overflow: visible;
    text-transform: uppercase;
    cursor: pointer; }
    .standard-styles .navbar-info .mode-select:hover {
      text-decoration: underline; }
    .standard-styles .navbar-info .mode-select .menu {
      position: absolute;
      right: 0;
      top: calc(100% + 12px);
      background-color: #ffffff;
      box-shadow: 0 2px 25px 1px rgba(0, 0, 0, 0.18);
      padding: 16px;
      text-align: center;
      z-index: 50;
      border-radius: 6px; }
      .standard-styles .navbar-info .mode-select .menu a {
        display: block;
        margin-bottom: 8px; }
        .standard-styles .navbar-info .mode-select .menu a:last-child {
          margin-bottom: 0; }
  .standard-styles .navbar-info .my-documents {
    width: 110px;
    height: 16px;
    overflow: visible;
    margin-left: 48px;
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 12px;
    font-weight: bold;
    line-height: 16px;
    letter-spacing: 1px;
    position: relative; }
    .standard-styles .navbar-info .my-documents .notification {
      position: absolute;
      top: 28px;
      left: 16px;
      background-color: #4c84f5;
      z-index: 800;
      width: 280px;
      border-radius: 6px;
      box-shadow: 0 2px 25px 1px rgba(0, 0, 0, 0.26);
      color: #ffffff;
      padding: 16px 24px 0 24px;
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 18px;
      font-weight: normal;
      line-height: 26px;
      letter-spacing: 1px; }
      .standard-styles .navbar-info .my-documents .notification .button {
        float: right;
        padding: 0;
        margin: 16px 0 0 0;
        color: #ffffff;
        background-color: unset; }
        .standard-styles .navbar-info .my-documents .notification .button:hover {
          background-color: unset; }
      .standard-styles .navbar-info .my-documents .notification .caret {
        width: 0;
        height: 0;
        position: absolute;
        top: -7px;
        left: 36px;
        border-radius: 3px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid #4c84f5; }
  .standard-styles .navbar-info .top-nav-link {
    margin-left: 49px; }
  .standard-styles .navbar-info .navbar-logout {
    display: flex; }
    .standard-styles .navbar-info .navbar-logout .logout-icon {
      margin-left: 5px; }
    .standard-styles .navbar-info .navbar-logout a {
      margin-top: 1px; }

.standard-styles .navbar__brand {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 24px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: normal;
  width: 230px;
  height: 24px; }
  .standard-styles .navbar__brand a,
  .standard-styles .navbar__brand a:link,
  .standard-styles .navbar__brand a:visited,
  .standard-styles .navbar__brand a:focus,
  .standard-styles .navbar__brand a:hover,
  .standard-styles .navbar__brand a:active {
    color: #4c84f5;
    text-decoration: none;
    text-transform: uppercase; }
  .standard-styles .navbar__brand a:hover {
    text-decoration: none; }
  .standard-styles .navbar__brand a {
    text-transform: none !important; }

.standard-styles .navbar__items {
  list-style: none;
  display: inline;
  font-variant: small-caps; }
  .standard-styles .navbar__items li {
    margin-right: 10px;
    display: inline-block; }

.standard-styles .account-dropdown {
  width: 192px;
  position: absolute;
  top: 72px;
  left: 100%;
  margin: auto 0 auto -280px;
  padding: 0;
  z-index: 777;
  background-color: #ffffff;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: 1px solid #f7f7f7;
  box-shadow: 0 0 6px 0 rgba(87, 159, 253, 0.1);
  animation: fadein 0.5s; }
  .standard-styles .account-dropdown ul {
    list-style: none;
    padding: 10px;
    margin: 0; }
    .standard-styles .account-dropdown ul a,
    .standard-styles .account-dropdown ul a:link,
    .standard-styles .account-dropdown ul a:visited,
    .standard-styles .account-dropdown ul a:focus,
    .standard-styles .account-dropdown ul a:hover,
    .standard-styles .account-dropdown ul a:active {
      color: #000000;
      text-decoration: none;
      text-transform: uppercase; }
    .standard-styles .account-dropdown ul a:hover {
      text-decoration: underline; }
    .standard-styles .account-dropdown ul li {
      text-align: center;
      margin: 8px auto; }
    .standard-styles .account-dropdown ul li:last-child {
      border-bottom: none; }

.standard-styles .popup-fuzz--confirmation-popup .button-options {
  display: flex;
  flex-direction: column;
  align-items: stretch; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .popup-fuzz--confirmation-popup .button-options {
      flex-direction: row-reverse;
      justify-content: center; } }
  .standard-styles .popup-fuzz--confirmation-popup .button-options button {
    margin: 20px 0 0 0; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .popup-fuzz--confirmation-popup .button-options button:last-child {
        margin-right: 10px; } }

@keyframes fadein {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

/* Firefox < 16 */
@-moz-keyframes fadein {
  .standard-styles from {
    opacity: 0; }
  .standard-styles to {
    opacity: 1; } }

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  .standard-styles from {
    opacity: 0; }
  .standard-styles to {
    opacity: 1; } }

/* Internet Explorer */
@-ms-keyframes fadein {
  .standard-styles from {
    opacity: 0; }
  .standard-styles to {
    opacity: 1; } }

/* Opera < 12.1 */
@-o-keyframes fadein {
  .standard-styles from {
    opacity: 0; }
  .standard-styles to {
    opacity: 1; } }
