.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .back-camera {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding-bottom: 47px; }
  .standard-styles .back-camera .first-info {
    display: none; }
    @media only screen and (min-width: 1200px) {
      .standard-styles .back-camera .first-info {
        text-align: center;
        display: block;
        font-family: 'AvertaPE', 'sans-serif';
        font-size: 24px;
        font-weight: normal;
        line-height: 32px;
        letter-spacing: normal;
        max-width: 600px;
        margin: 0 auto;
        margin-bottom: 16px; } }
  .standard-styles .back-camera .second-info {
    display: none; }
    @media only screen and (min-width: 1200px) {
      .standard-styles .back-camera .second-info {
        display: block;
        font-family: 'AvertaPE', 'sans-serif';
        font-size: 16px;
        font-weight: normal;
        line-height: 24px;
        letter-spacing: normal;
        width: 80%;
        padding-left: 48px; } }
  @media only screen and (min-width: 1200px) {
    .standard-styles .back-camera {
      left: unset;
      width: 50%; } }
  @media only screen and (min-width: 1200px) {
    .standard-styles .back-camera .publisher-wrapper {
      position: relative;
      width: 80%;
      padding-bottom: 60%; } }
  .standard-styles .back-camera #publisher {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0; }
    .standard-styles .back-camera #publisher .OT_widget-container {
      background-color: transparent; }
  .standard-styles .back-camera #capture-id {
    display: none; }
  .standard-styles .back-camera .id-place-wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center; }
  .standard-styles .back-camera .id-place {
    border-image: url("../../../../../../icons/icon-id-frame.png") round;
    border-image-slice: 80%;
    border-image-width: 50%;
    border-style: solid;
    width: 0; }
  .standard-styles .back-camera .camera__button {
    position: fixed;
    height: 60px;
    width: 60px;
    background: #4c84f5;
    border-radius: 100%;
    background-image: url("../../../../../../icons/svg/icon-camera-alt-material.svg");
    background-repeat: no-repeat;
    background-position: center;
    border: none; }
    @media only screen and (min-width: 1200px) {
      .standard-styles .back-camera .camera__button {
        display: none; } }
    @media (orientation: portrait) {
      .standard-styles .back-camera .camera__button {
        bottom: 20px;
        left: calc(50% - 30px); } }
    @media (orientation: landscape) {
      .standard-styles .back-camera .camera__button {
        right: 20px;
        top: calc(50% - 30px); } }
