.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .navbar--signup, .standard-styles .navbar--checkout {
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06); }

.standard-styles .navbar--checkout {
  margin-bottom: 0; }
