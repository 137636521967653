.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

@media only screen and (min-width: 1000px) {
  .standard-styles .education {
    max-width: 881px; } }

@media only screen and (min-width: 1200px) {
  .standard-styles .education {
    max-width: 1038px; } }

.standard-styles .education h2 {
  margin: 48px 0 32px 0;
  letter-spacing: normal;
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 36px;
  font-weight: bold;
  line-height: 44px;
  letter-spacing: normal; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .education h2 {
      margin: 64px 0 48px 0;
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 48px;
      font-weight: bold;
      line-height: 56px;
      letter-spacing: normal; } }

.standard-styles .education h3 {
  margin: 64px 0 32px 0;
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 30px;
  font-weight: bold;
  line-height: 38px;
  letter-spacing: normal; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .education h3 {
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 36px;
      font-weight: bold;
      line-height: 44px;
      letter-spacing: normal; } }

.standard-styles .education h5 {
  margin: 0 0 16px 0;
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 24px;
  font-weight: bold;
  line-height: 30px;
  letter-spacing: normal;
  color: #333f4c;
  text-transform: uppercase; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .education h5 {
      margin-bottom: 8px; } }

.standard-styles .education p {
  margin: 0;
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 24px;
  font-weight: 300;
  line-height: 30px;
  letter-spacing: normal; }

.standard-styles .education .probate-negative, .standard-styles .education .probate-positive {
  display: block;
  margin: 48px auto 0 auto;
  width: 725px;
  max-width: 100%;
  min-height: 100px;
  height: max-content;
  max-height: 175px;
  background: url("../../../icons/svg/icon-probate-negative.svg") no-repeat center;
  background-size: contain; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .education .probate-negative, .standard-styles .education .probate-positive {
      height: 175px; } }

.standard-styles .education .probate-positive {
  margin-bottom: 16px;
  width: 488px;
  background-image: url("../../../icons/svg/icon-probate-positive.svg"); }

.standard-styles .education .price-notice {
  margin: 24px auto;
  text-align: center;
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 16px;
  font-weight: bold;
  line-height: 28px;
  letter-spacing: normal;
  color: #525252; }

.standard-styles .education .image-flow {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-bottom: 40px; }
  .standard-styles .education .image-flow p {
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 16px;
    font-weight: normal;
    line-height: 28px;
    letter-spacing: normal;
    color: #525252;
    margin-top: 16px; }
    .standard-styles .education .image-flow p.header {
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 18px;
      font-weight: bold;
      line-height: 28px;
      letter-spacing: normal;
      padding-bottom: 8px;
      border-bottom: 1px solid #dfe3e9; }
  .standard-styles .education .image-flow .left,
  .standard-styles .education .image-flow .right {
    text-align: left; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .education .image-flow .left,
      .standard-styles .education .image-flow .right {
        max-width: 70%; } }
    .standard-styles .education .image-flow .left .button,
    .standard-styles .education .image-flow .right .button {
      width: 100%; }
  .standard-styles .education .image-flow img {
    object-fit: contain; }
    @media only screen and (min-width: 0px) and (max-width: 599px) {
      .standard-styles .education .image-flow img {
        width: 100%;
        order: -1;
        margin-bottom: 32px; } }
    @media only screen and (min-width: 600px) and (max-width: 999px) {
      .standard-styles .education .image-flow img {
        width: 100%;
        order: -1;
        margin-bottom: 32px; } }
  @media only screen and (min-width: 1000px) {
    .standard-styles .education .image-flow {
      flex-direction: row;
      margin-top: 64px;
      margin-bottom: 0; }
      .standard-styles .education .image-flow .left {
        margin-bottom: 0;
        margin-right: 80px; } }
  @media only screen and (min-width: 1000px) and (min-width: 1000px) {
    .standard-styles .education .image-flow .right.fixed-width {
      max-width: 294px; } }
  @media only screen and (min-width: 1000px) {
      .standard-styles .education .image-flow .right .list-box-component {
        margin-bottom: 24px; } }

@media only screen and (min-width: 1000px) {
  .standard-styles .education .image-flow--column {
    flex-direction: column !important; }
    .standard-styles .education .image-flow--column .left {
      margin-right: 0 !important; } }

@media only screen and (min-width: 1200px) {
  .standard-styles .education .image-flow--column {
    flex-direction: row !important; }
    .standard-styles .education .image-flow--column .left {
      margin-right: 80px !important; } }

.standard-styles .education .cta-box {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin: 40px 0 24px 0;
  padding: 24px;
  border-radius: 4px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.12); }
  .standard-styles .education .cta-box h3 {
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 36px;
    font-weight: bold;
    line-height: 44px;
    letter-spacing: normal;
    margin: 0 0 24px 0;
    text-align: left; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .education .cta-box h3 {
        margin: 0 80px 0 0; } }
  .standard-styles .education .cta-box .button {
    width: 100%; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .education .cta-box {
      margin: 48px 16px 0 16px;
      padding: 48px;
      flex-direction: row;
      width: 100%; }
      .standard-styles .education .cta-box .button {
        width: 295px; } }

.standard-styles .education > .button-flow {
  margin-top: 80px;
  margin-bottom: 16px; }
