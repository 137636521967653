@media only screen and (min-width: 0px) and (max-width: 599px) {
  .standard-styles .footer--appfooter {
    margin: 0 8px 16px 8px;
    padding: 6px 16px; } }

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .standard-styles .footer--appfooter {
    margin: 0 16px 16px 16px;
    padding: 21px 24px; } }

@media only screen and (min-width: 1000px) {
  .standard-styles .footer--appfooter {
    margin: 0 96px 32px 96px;
    padding: 16px 80px 80px 80px; } }

@media only screen and (min-width: 0px) and (max-width: 599px) {
  .standard-styles .footer--appfooter {
    margin: 0 8px 16px 8px;
    padding: 6px 16px; } }

@media only screen and (min-width: 600px) and (max-width: 999px) {
  .standard-styles .footer--appfooter {
    margin: 0 16px 16px 16px;
    padding: 21px 24px; } }

@media only screen and (min-width: 1000px) {
  .standard-styles .footer--appfooter {
    margin: 0 96px 32px 96px;
    padding: 16px 80px 80px 80px; } }

.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .footer--appfooter {
  display: block;
  max-width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 4px; }

.standard-styles .footer--authenticationfooter {
  width: 100%;
  height: 64px;
  background-color: #ffffff;
  padding: 0 48px;
  box-shadow: 0 2px 25px 1px rgba(0, 0, 0, 0.06);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  display: none;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  position: absolute;
  top: calc(100% - 64px);
  left: 0; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .footer--authenticationfooter {
      display: flex; } }
  .standard-styles .footer--authenticationfooter .footer__section {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: center;
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 12px;
    font-weight: normal;
    line-height: 15px;
    letter-spacing: 0.13px;
    color: #757575; }
    .standard-styles .footer--authenticationfooter .footer__section a,
    .standard-styles .footer--authenticationfooter .footer__section a:link,
    .standard-styles .footer--authenticationfooter .footer__section a:visited,
    .standard-styles .footer--authenticationfooter .footer__section a:focus,
    .standard-styles .footer--authenticationfooter .footer__section a:hover,
    .standard-styles .footer--authenticationfooter .footer__section a:active {
      color: #757575;
      text-decoration: none;
      text-transform: none;
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 12px;
      font-weight: normal;
      line-height: 15px;
      letter-spacing: normal;
      font-variant: none;
      letter-spacing: unset; }
    .standard-styles .footer--authenticationfooter .footer__section a:hover {
      text-decoration: underline; }
    .standard-styles .footer--authenticationfooter .footer__section .section__item {
      margin-left: 32px;
      flex-grow: 1; }
      .standard-styles .footer--authenticationfooter .footer__section .section__item .mcafee-logo {
        width: 66px;
        height: 24px;
        display: block;
        background-size: cover;
        opacity: 0.7;
        background-image: url("../icons/icon-mcafee.png"); }
      .standard-styles .footer--authenticationfooter .footer__section .section__item .norton-logo {
        width: 60px;
        height: 24px;
        display: block;
        background-size: cover;
        opacity: 0.7;
        background-image: url("../icons/icon-norton.png"); }
      .standard-styles .footer--authenticationfooter .footer__section .section__item .icon-heart {
        display: inline-block;
        width: 13px;
        height: 12px;
        margin: 0 5px -2px 5px; }
        .standard-styles .footer--authenticationfooter .footer__section .section__item .icon-heart:before {
          position: relative;
          top: 2px;
          content: url("~@willing-shared/assets/images/heart.svg"); }

.standard-styles .footer--fixed {
  position: fixed;
  bottom: 0px; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .standard-styles .footer--fixed {
      position: relative; } }
  .standard-styles .footer--fixed .footer__section:nth-child(2) {
    margin-left: -68px; }
