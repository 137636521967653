.standard-styles .document-view .top .right .recording .recording__label {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  letter-spacing: normal;
  font-variant: small-caps; }

.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .document-view {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-height: 100% !important; }
  .standard-styles .document-view .top {
    flex: 1;
    overflow: auto; }
    .standard-styles .document-view .top.top--admin {
      margin-top: 19px; }
      @media only screen and (min-width: 1000px) {
        .standard-styles .document-view .top.top--admin {
          margin-top: 0; } }
    .standard-styles .document-view .top .left {
      overflow: auto;
      height: 100% !important;
      width: 100%; }
      @media only screen and (min-width: 1000px) {
        .standard-styles .document-view .top .left {
          position: static;
          float: left;
          width: calc(100% - 320px);
          padding-right: 12px;
          border-right: solid 1px #d2d2d2; } }
    .standard-styles .document-view .top .right {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      align-content: center;
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      z-index: 100; }
      @media only screen and (min-width: 1000px) {
        .standard-styles .document-view .top .right {
          display: block;
          position: static;
          float: right;
          text-align: left;
          width: 320px;
          max-height: 100% !important;
          overflow: auto;
          padding: 48px; } }
      .standard-styles .document-view .top .right .recording {
        width: 100%;
        text-align: center;
        background-color: #f4f5f7; }
        @media only screen and (min-width: 1000px) {
          .standard-styles .document-view .top .right .recording {
            background: none;
            text-align: left;
            margin: 0 0 24px 0; } }
        .standard-styles .document-view .top .right .recording .recording__indicator {
          display: inline-block;
          width: 12px;
          height: 12px;
          border-radius: 6px;
          background: #ff5a5a;
          border: 2px solid #dd3a3a;
          margin-right: 8px;
          position: relative;
          top: 1px; }
        .standard-styles .document-view .top .right .recording .recording__label {
          display: inline-block;
          text-transform: uppercase; }
  .standard-styles .document-view .bottom {
    box-shadow: 0 -3px 5px 0 rgba(210, 210, 210, 0.25);
    border-top: solid 1px rgba(255, 255, 255, 0);
    padding: 24px 40px;
    flex: 0; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .document-view .bottom {
        width: 100%;
        position: relative; } }
    .standard-styles .document-view .bottom .notary-button-flow {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      align-content: center; }
      .standard-styles .document-view .bottom .notary-button-flow .button {
        margin: 0 8px 8px 0;
        min-width: 250px; }
  .standard-styles .document-view .document-view__loading-indicator {
    text-align: center;
    margin-top: 100px; }

.standard-styles .blink {
  -webkit-animation: 2s linear infinite condemned_blink_effect;
  animation: 2s linear infinite condemned_blink_effect; }

@-webkit-keyframes condemned_blink_effect {
  .standard-styles 0% {
    visibility: hidden; }
  .standard-styles 50% {
    visibility: hidden; }
  .standard-styles 100% {
    visibility: visible; } }

@keyframes condemned_blink_effect {
  0% {
    visibility: hidden; }
  50% {
    visibility: hidden; }
  100% {
    visibility: visible; } }

.standard-styles .witness-popup {
  text-align: right; }
  .standard-styles .witness-popup > p {
    text-align: center; }
