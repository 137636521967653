.standard-styles .signing-popup .popup__drawbox .drawbox__line {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Chrome and Opera */ }

.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .signing-popup .popup__drawbox .drawbox__line {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Chrome and Opera */ }

.standard-styles .signing-popup .popup__headers, .standard-styles .signing-popup .popup__drawbox {
  margin-bottom: 19px; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .signing-popup .popup__headers, .standard-styles .signing-popup .popup__drawbox {
      margin-bottom: 40px; } }

.standard-styles .signing-popup {
  height: 100%;
  width: 100%;
  padding: 34px;
  text-align: left; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .signing-popup {
      height: auto;
      width: auto;
      padding: 40px; } }
  .standard-styles .signing-popup .popup__headers .headers__step {
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 22px;
    font-weight: bold;
    line-height: 28px;
    letter-spacing: normal;
    color: #454545; }
  .standard-styles .signing-popup .popup__headers .headers__hero {
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 30px;
    font-weight: bold;
    line-height: 37px;
    letter-spacing: normal;
    color: #000000; }
  .standard-styles .signing-popup .popup__drawbox {
    position: relative;
    width: 100%;
    height: calc(100% - 133px);
    max-height: 293px;
    margin-left: auto;
    margin-right: auto;
    background-color: #fcfafa;
    border: 1px solid #c3c2c2;
    border-radius: 8px; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .signing-popup .popup__drawbox {
        height: 293px;
        width: 969px; } }
    .standard-styles .signing-popup .popup__drawbox.drawbox--initials {
      width: 480px;
      max-width: 100%; }
    .standard-styles .signing-popup .popup__drawbox .drawbox__canvas {
      width: 100%;
      height: 100%;
      position: relative;
      z-index: 1; }
    .standard-styles .signing-popup .popup__drawbox .drawbox__line {
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 20px;
      font-weight: bold;
      line-height: 30px;
      letter-spacing: normal;
      color: #767676;
      position: absolute;
      border-top: 2px dashed #c3c3c3;
      width: calc(100% - 64px);
      top: 66%;
      left: 32px; }
      @media only screen and (min-width: 1000px) {
        .standard-styles .signing-popup .popup__drawbox .drawbox__line {
          font-family: 'AvertaPE', 'sans-serif';
          font-size: 24px;
          font-weight: bold;
          line-height: 30px;
          letter-spacing: normal; } }
  .standard-styles .signing-popup .popup__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center; }
    @media only screen and (max-width: 499px) {
      .standard-styles .signing-popup .popup__buttons {
        flex-direction: column; } }
    .standard-styles .signing-popup .popup__buttons .button {
      margin: 0; }
    .standard-styles .signing-popup .popup__buttons .popup__secondary-buttons {
      flex: 1;
      display: flex;
      justify-content: space-between;
      margin-right: 8px; }
      @media only screen and (max-width: 499px) {
        .standard-styles .signing-popup .popup__buttons .popup__secondary-buttons {
          justify-content: center;
          margin-right: 0; } }
      .standard-styles .signing-popup .popup__buttons .popup__secondary-buttons button:first-child {
        margin-left: -32px; }
        @media only screen and (max-width: 499px) {
          .standard-styles .signing-popup .popup__buttons .popup__secondary-buttons button:first-child {
            margin-left: 0; } }
      .standard-styles .signing-popup .popup__buttons .popup__secondary-buttons button:last-child {
        margin-left: 8px; }
    .standard-styles .signing-popup .popup__buttons .back-icon {
      display: inline-block;
      width: 16px;
      position: relative;
      top: 3px;
      margin-right: 8px;
      content: url("../../../icons/svg/icon-arrow-forward.svg");
      transform: rotate(180deg);
      vertical-align: center; }
