.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .dateselect {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  margin-bottom: 16px; }
  .standard-styles .dateselect select {
    margin-right: 8px; }

.standard-styles .dateselect--mobile-column {
  flex-direction: column;
  align-items: flex-start; }
  @media only screen and (min-width: 0px) and (max-width: 599px) {
    .standard-styles .dateselect--mobile-column * {
      width: 100%; }
      .standard-styles .dateselect--mobile-column * .select-input {
        min-width: 100%;
        margin-bottom: 16px; }
      .standard-styles .dateselect--mobile-column *:last-child .select-input {
        margin-bottom: 0; } }
  @media only screen and (min-width: 600px) and (max-width: 999px) {
    .standard-styles .dateselect--mobile-column * {
      width: 100%; }
      .standard-styles .dateselect--mobile-column * .select-input {
        min-width: 100%;
        margin-bottom: 16px; }
      .standard-styles .dateselect--mobile-column *:last-child .select-input {
        margin-bottom: 0; } }
  @media only screen and (min-width: 1000px) {
    .standard-styles .dateselect--mobile-column {
      flex-direction: row;
      align-items: center; } }

@media only screen and (min-width: 1000px) {
  .standard-styles .dateselect--register {
    width: 520px;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch; }
    .standard-styles .dateselect--register * {
      flex-grow: 2;
      padding-right: 8px; }
      .standard-styles .dateselect--register *:last-child {
        padding-right: 0; }
      .standard-styles .dateselect--register * .select-input {
        min-width: 100%; } }
