.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .locked-text {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 18px;
  font-weight: normal;
  line-height: 23px;
  letter-spacing: normal; }
  .standard-styles .locked-text:before {
    content: url("../../../../icons/svg/icon-lock.svg");
    margin-right: 8px;
    position: relative;
    top: 3px; }

.standard-styles .ssn-input {
  font-family: 'AvertaPE', 'sans-serif';
  font-size: 36px;
  font-weight: normal;
  line-height: 1;
  letter-spacing: normal;
  margin-bottom: 8px !important; }
  .standard-styles .ssn-input .light {
    font-weight: 300; }
