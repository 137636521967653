.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .id-viewer {
  padding: 60px 68px 60px 80px; }
  .standard-styles .id-viewer h4 {
    margin: 0 0 24px 0;
    font-family: 'AvertaPE', 'sans-serif';
    font-size: 36px;
    font-weight: bold;
    line-height: 1;
    letter-spacing: normal; }
  .standard-styles .id-viewer .id-viewer__ids {
    width: 100%;
    display: flex;
    justify-content: space-between; }
    .standard-styles .id-viewer .id-viewer__ids img {
      width: 40%;
      height: auto;
      align-self: flex-start; }
      .standard-styles .id-viewer .id-viewer__ids img:hover {
        cursor: pointer; }
  .standard-styles .id-viewer .id-viewer__actions {
    margin-top: 32px;
    display: flex;
    justify-content: flex-end; }
    .standard-styles .id-viewer .id-viewer__actions .button {
      margin: 0 0 0 10px; }

.standard-styles .popup--id-viewer {
  width: 60%; }
  .standard-styles .popup--id-viewer img {
    width: 100%; }
