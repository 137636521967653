.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .success-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .success-info {
      width: 650px; } }
  .standard-styles .success-info .icon {
    width: 96px;
    height: 96px;
    background-image: url("../../../../../icons/svg/icon-check-green.svg");
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .success-info .icon {
        width: 120px;
        height: 120px; } }
