.standard-styles .wandalabel {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: flex-start;
  position: relative;
  z-index: 3;
  margin-top: 32px;
  margin-bottom: 8px; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .wandalabel {
      flex-direction: row;
      align-items: flex-start;
      margin-top: 40px;
      margin-bottom: 32px; } }
  .standard-styles .wandalabel .wandaholder {
    min-width: 95px;
    min-height: 95px; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .wandalabel .wandaholder {
        min-width: 112px;
        min-height: 112px;
        margin-top: 40px; } }
  .standard-styles .wandalabel .wandaheaders {
    flex-grow: 2; }
  .standard-styles .wandalabel h2 {
    font-size: 28px;
    line-height: 1;
    text-align: center; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .wandalabel h2 {
        margin-left: 32px;
        font-size: 48px;
        text-align: left; } }
  .standard-styles .wandalabel p {
    text-align: center;
    margin-top: -16px; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .wandalabel p {
        margin-left: 32px;
        text-align: left; } }

.standard-styles .wandalabel--vertical {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center; }
