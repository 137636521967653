.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .checkout-wrapper,
.standard-styles .new-checkout-wrapper {
  min-height: 100%;
  margin-bottom: 24px;
  padding-right: 0; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .checkout-wrapper,
    .standard-styles .new-checkout-wrapper {
      min-height: calc(100% - 5px);
      padding-top: 32px;
      margin-bottom: 118px; } }

@media only screen and (min-width: 1000px) {
  .standard-styles .close-bar--newlayout {
    padding-top: 30px; } }

.standard-styles .close-bar--newlayout:after {
  display: none;
  width: 16px;
  height: 16px;
  content: url("../../icons/svg/icon-close-transparent.svg");
  top: 28px;
  position: absolute;
  left: calc(100% - 44px); }
  @media only screen and (min-width: 1000px) {
    .standard-styles .close-bar--newlayout:after {
      position: absolute;
      top: unset;
      left: calc(100% - 64px); } }

.standard-styles .checkout-container,
.standard-styles .new-checkout-container {
  display: flex;
  flex-direction: column; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .checkout-container,
    .standard-styles .new-checkout-container {
      flex-direction: row; } }
  .standard-styles .checkout-container .checkout__box,
  .standard-styles .new-checkout-container .checkout__box {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-start; }
    .standard-styles .checkout-container .checkout__box .checkout--full-width,
    .standard-styles .new-checkout-container .checkout__box .checkout--full-width {
      width: 100%; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .checkout-container .checkout__box,
      .standard-styles .new-checkout-container .checkout__box {
        width: 50%; } }
  .standard-styles .checkout-container hr,
  .standard-styles .new-checkout-container hr {
    box-sizing: border-box;
    height: 3px;
    width: 100%;
    border: 1px solid #e6e6e6;
    margin: 24px 0 22px 0;
    background-color: #e6e6e6; }
  .standard-styles .checkout-container .checkout__box-left,
  .standard-styles .new-checkout-container .checkout__box-left {
    align-items: center; }
    .standard-styles .checkout-container .checkout__box-left.checkout__box--hide-mob,
    .standard-styles .new-checkout-container .checkout__box-left.checkout__box--hide-mob {
      display: none; }
      @media only screen and (min-width: 1000px) {
        .standard-styles .checkout-container .checkout__box-left.checkout__box--hide-mob,
        .standard-styles .new-checkout-container .checkout__box-left.checkout__box--hide-mob {
          display: inherit; } }
  .standard-styles .checkout-container .checkout__box-right,
  .standard-styles .new-checkout-container .checkout__box-right {
    align-items: center; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .checkout-container .checkout__box-right,
      .standard-styles .new-checkout-container .checkout__box-right {
        max-width: 500px;
        align-items: flex-start; } }
  @media only screen and (min-width: 0px) and (max-width: 599px) {
    .standard-styles .checkout-container,
    .standard-styles .new-checkout-container {
      align-items: center; } }
  @media only screen and (min-width: 1000px) {
    .standard-styles .checkout-container,
    .standard-styles .new-checkout-container {
      flex-direction: row; } }
  .standard-styles .checkout-container .checkout-form,
  .standard-styles .new-checkout-container .checkout-form {
    margin: 0 auto;
    width: 492px; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .checkout-container .checkout-form,
      .standard-styles .new-checkout-container .checkout-form {
        margin-right: 45px; } }
    .standard-styles .checkout-container .checkout-form .form__header,
    .standard-styles .new-checkout-container .checkout-form .form__header {
      background: none; }
      .standard-styles .checkout-container .checkout-form .form__header .pricebox,
      .standard-styles .new-checkout-container .checkout-form .form__header .pricebox {
        display: none; }
    .standard-styles .checkout-container .checkout-form form .elements,
    .standard-styles .new-checkout-container .checkout-form form .elements {
      flex-wrap: wrap; }
      .standard-styles .checkout-container .checkout-form form .elements .stripe-container,
      .standard-styles .new-checkout-container .checkout-form form .elements .stripe-container {
        border: solid 2px #ebecf0;
        border-radius: 5px;
        padding: 11px 16px;
        flex-grow: 1;
        flex-shrink: 0;
        margin-bottom: 0; }
        .standard-styles .checkout-container .checkout-form form .elements .stripe-container:nth-child(2),
        .standard-styles .new-checkout-container .checkout-form form .elements .stripe-container:nth-child(2) {
          margin: 0;
          margin-right: 22px; }
        .standard-styles .checkout-container .checkout-form form .elements .stripe-container:first-child,
        .standard-styles .new-checkout-container .checkout-form form .elements .stripe-container:first-child {
          width: 100%;
          margin-bottom: 44px; }
        .standard-styles .checkout-container .checkout-form form .elements .stripe-container.stripe-container--position,
        .standard-styles .new-checkout-container .checkout-form form .elements .stripe-container.stripe-container--position {
          position: relative; }
        .standard-styles .checkout-container .checkout-form form .elements .stripe-container .stripe-label--position,
        .standard-styles .new-checkout-container .checkout-form form .elements .stripe-container .stripe-label--position {
          position: absolute;
          top: -24px;
          left: 0; }
    .standard-styles .checkout-container .checkout-form form .button--paybutton,
    .standard-styles .new-checkout-container .checkout-form form .button--paybutton {
      min-height: 48px !important;
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 14px;
      font-weight: 600;
      line-height: 12px;
      letter-spacing: 1.17px; }
    .standard-styles .checkout-container .checkout-form .checkout-footer__section,
    .standard-styles .new-checkout-container .checkout-form .checkout-footer__section {
      justify-content: space-evenly; }
      .standard-styles .checkout-container .checkout-form .checkout-footer__section .checkout-section__item .logo,
      .standard-styles .new-checkout-container .checkout-form .checkout-footer__section .checkout-section__item .logo {
        width: 66px;
        height: 26px; }

.standard-styles .checkout-wrapper {
  min-height: 100%;
  margin-bottom: 24px; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .checkout-wrapper {
      min-height: calc(100% - 5px);
      padding-top: 32px;
      margin-bottom: 118px; } }
  .standard-styles .checkout-wrapper .pay-later-link-mobile {
    display: none;
    margin: 0px auto;
    text-align: center; }
    @media only screen and (min-width: 0px) and (max-width: 599px) {
      .standard-styles .checkout-wrapper .pay-later-link-mobile {
        display: block;
        width: 80%; } }
    @media only screen and (min-width: 600px) and (max-width: 999px) {
      .standard-styles .checkout-wrapper .pay-later-link-mobile {
        display: block;
        width: 400px; } }
    .standard-styles .checkout-wrapper .pay-later-link-mobile .button--tertiary {
      margin-top: 40px;
      width: 100%;
      border: 1px solid; }

@media only screen and (min-width: 1000px) {
  .standard-styles .close-bar {
    padding-top: 30px; } }

@media only screen and (min-width: 0px) and (max-width: 599px) {
  .standard-styles .close-bar--pricing:after {
    top: -44px; } }

.standard-styles .checkout-container {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start; }
  @media only screen and (min-width: 0px) and (max-width: 599px) {
    .standard-styles .checkout-container {
      align-items: center; } }
  @media only screen and (min-width: 1000px) {
    .standard-styles .checkout-container {
      flex-direction: row;
      padding-right: 80px; } }
  .standard-styles .checkout-container > div:last-child {
    order: 1;
    width: 492px;
    max-width: 100%;
    margin: 0 auto; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .checkout-container > div:last-child {
        width: auto;
        margin-left: 45px; } }
  .standard-styles .checkout-container .order-summary {
    border: 1px solid #e6e6e6;
    border-radius: 2px;
    background-color: #ffffff;
    box-shadow: 0 0 13px 0 rgba(0, 0, 0, 0.06);
    color: #333647;
    padding: 24px;
    width: 100%; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .checkout-container .order-summary {
        width: 402px;
        margin-bottom: 44px; } }
    .standard-styles .checkout-container .order-summary .order-summary__header {
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 24px;
      font-weight: 600;
      line-height: 39px;
      letter-spacing: normal; }
      @media only screen and (min-width: 1000px) {
        .standard-styles .checkout-container .order-summary .order-summary__header {
          border-bottom: 1px dashed #9b9b9b;
          margin-bottom: 10px;
          padding-bottom: 10px; } }
    @media only screen and (min-width: 1000px) {
      .standard-styles .checkout-container .order-summary .credit__body {
        margin-top: 32px; } }
    .standard-styles .checkout-container .order-summary .order-summary__body .body__title,
    .standard-styles .checkout-container .order-summary .credit__body .body__title {
      display: none; }
      @media only screen and (min-width: 1000px) {
        .standard-styles .checkout-container .order-summary .order-summary__body .body__title,
        .standard-styles .checkout-container .order-summary .credit__body .body__title {
          display: block;
          color: #414042;
          font-family: 'AvertaPE', 'sans-serif';
          font-size: 18px;
          font-weight: 600;
          line-height: 39px;
          letter-spacing: normal; } }
    .standard-styles .checkout-container .order-summary .order-summary__body .body__discount,
    .standard-styles .checkout-container .order-summary .credit__body .body__discount {
      display: flex; }
    .standard-styles .checkout-container .order-summary .order-summary__body .body__content,
    .standard-styles .checkout-container .order-summary .credit__body .body__content {
      display: flex; }
      .standard-styles .checkout-container .order-summary .order-summary__body .body__content .link,
      .standard-styles .checkout-container .order-summary .credit__body .body__content .link {
        color: #4c84f5;
        cursor: pointer;
        text-transform: none;
        font-family: 'AvertaPE', 'sans-serif';
        font-size: 16px;
        font-weight: normal;
        line-height: normal;
        letter-spacing: 0.2px; }
    .standard-styles .checkout-container .order-summary .order-summary__body .body__content,
    .standard-styles .checkout-container .order-summary .order-summary__body .body__discount,
    .standard-styles .checkout-container .order-summary .credit__body .body__content,
    .standard-styles .checkout-container .order-summary .credit__body .body__discount {
      font-family: 'AvertaPE', 'sans-serif';
      font-size: 18px;
      font-weight: normal;
      line-height: 39px;
      letter-spacing: normal;
      color: #525252;
      flex-direction: row; }
      .standard-styles .checkout-container .order-summary .order-summary__body .body__content > div:first-child,
      .standard-styles .checkout-container .order-summary .order-summary__body .body__discount > div:first-child,
      .standard-styles .checkout-container .order-summary .credit__body .body__content > div:first-child,
      .standard-styles .checkout-container .order-summary .credit__body .body__discount > div:first-child {
        flex: 1; }
    .standard-styles .checkout-container .order-summary .order-summary__footer {
      display: flex;
      flex-direction: row;
      border-top: 1px dashed #9b9b9b;
      margin-top: 10px;
      padding-top: 10px; }
      .standard-styles .checkout-container .order-summary .order-summary__footer > div:first-child {
        flex: 1;
        font-family: 'AvertaPE', 'sans-serif';
        font-size: 18px;
        font-weight: 600;
        line-height: 39px;
        letter-spacing: normal; }
      .standard-styles .checkout-container .order-summary .order-summary__footer > div:last-child {
        font-family: 'AvertaPE', 'sans-serif';
        font-size: 18px;
        font-weight: normal;
        line-height: 39px;
        letter-spacing: normal; }
      @media only screen and (min-width: 1000px) {
        .standard-styles .checkout-container .order-summary .order-summary__footer {
          margin-top: 58px; } }
  .standard-styles .checkout-container .list-box {
    width: 350px;
    margin-bottom: 48px; }
    @media only screen and (min-width: 600px) and (max-width: 999px) {
      .standard-styles .checkout-container .list-box {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        justify-content: center;
        align-items: flex-start;
        align-content: center;
        padding-right: 16px;
        margin-bottom: 0;
        flex-grow: 2;
        width: calc(100% - 16px); } }
    .standard-styles .checkout-container .list-box ul {
      list-style-type: none;
      width: 100%;
      margin-top: 0; }
      @media only screen and (min-width: 0px) and (max-width: 599px) {
        .standard-styles .checkout-container .list-box ul {
          padding-left: 0; } }
      .standard-styles .checkout-container .list-box ul:first-child {
        margin-bottom: 64px; }
        @media only screen and (min-width: 600px) and (max-width: 999px) {
          .standard-styles .checkout-container .list-box ul:first-child {
            margin-bottom: 0; } }
      .standard-styles .checkout-container .list-box ul .right-float {
        float: right;
        font-weight: normal;
        line-height: 1; }
        .standard-styles .checkout-container .list-box ul .right-float:before {
          width: 22px;
          height: 22px;
          display: inline-block;
          position: relative;
          top: 4px;
          margin-right: 4px;
          content: url("../../icons/svg/icon-person.svg"); }
      .standard-styles .checkout-container .list-box ul li {
        font-family: 'AvertaPE', 'sans-serif';
        font-size: 16px;
        font-weight: normal;
        line-height: 1.75;
        letter-spacing: normal;
        padding: 8px 24px 8px 0;
        width: 100%;
        position: relative;
        border-bottom: solid 1px #dfe3e9; }
        .standard-styles .checkout-container .list-box ul li:after {
          position: absolute;
          left: calc(100% - 16px);
          display: inline-block;
          width: 16px;
          top: 10px;
          height: 12px;
          content: url("../../icons/svg/icon-small-blue-check.svg"); }
        .standard-styles .checkout-container .list-box ul li:first-child {
          font-weight: bold;
          font-size: 18px; }
      .standard-styles .checkout-container .list-box ul li.unchecked {
        padding-right: 0; }
        .standard-styles .checkout-container .list-box ul li.unchecked:after {
          content: none; }
      .standard-styles .checkout-container .list-box ul li.red:after {
        content: url("../../icons/svg/icon-red-x.svg"); }
  .standard-styles .checkout-container .list-box__checkout {
    display: none; }
    @media only screen and (min-width: 1000px) {
      .standard-styles .checkout-container .list-box__checkout {
        display: block; } }
    .standard-styles .checkout-container .list-box__checkout .pay-later-link {
      display: block;
      text-align: center;
      margin: 0px auto; }
      .standard-styles .checkout-container .list-box__checkout .pay-later-link .button--tertiary {
        margin-top: 40px;
        width: 100%;
        border: 1px solid; }
