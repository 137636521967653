.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

@media only screen and (min-width: 0px) and (max-width: 999px) {
  .standard-styles .address-input .text-input {
    width: 100%; }
  .standard-styles .address-input .text-input--3 {
    display: inline-block;
    width: calc(100% - 71px - 8px);
    margin-right: 0; } }
