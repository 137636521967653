.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .checkout-footer__section {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 48px; }
  .standard-styles .checkout-footer__section .checkout-section__item .logo {
    width: 100px;
    height: 44px;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
    opacity: 0.65; }
    .standard-styles .checkout-footer__section .checkout-section__item .logo.mcafee {
      background-image: url("../../../icons/icon-mcafee.png"); }
    .standard-styles .checkout-footer__section .checkout-section__item .logo.norton {
      background-image: url("../../../icons/icon-norton.png"); }
    .standard-styles .checkout-footer__section .checkout-section__item .logo.accredited-business {
      background-image: url("../../../icons/icon-bbb@2x.png"); }
