.standard-styles .gray-text {
  color: #a0a0a0; }

.standard-styles .amalfi {
  font-family: 'Amalfi', sans-serif; }

.standard-styles .ballpoint {
  font-family: 'Ballpoint', sans-serif; }

.standard-styles .stylededit {
  font-family: 'TheStyledEdit', sans-serif; }

.standard-styles .whiteoleander {
  font-family: 'WhiteOleander', sans-serif; }

.standard-styles .intro-check, .standard-styles .intro-error {
  width: 96px;
  height: 96px;
  background-size: cover;
  background-image: url("../../../../icons/svg/icon-check-green.svg"); }
  @media only screen and (min-width: 1000px) {
    .standard-styles .intro-check, .standard-styles .intro-error {
      width: 120px;
      height: 120px; } }

.standard-styles .intro-error {
  background-image: url("../../../../icons/svg/icon-error.svg"); }

.standard-styles .kba-questions {
  padding-bottom: 170px; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .kba-questions {
      padding-bottom: 50px; } }

.standard-styles .kba__footer {
  position: absolute;
  bottom: 0;
  top: auto;
  padding: 20px 12px;
  flex-direction: column;
  height: 150px;
  box-shadow: 0 -3px 5px 0 rgba(210, 210, 210, 0.25); }
  .standard-styles .kba__footer .label-box {
    min-width: initial;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    flex: 1; }
    .standard-styles .kba__footer .label-box .label {
      font-size: 18px; }
  .standard-styles .kba__footer .button {
    margin-top: 8px; }
  @media only screen and (min-width: 1000px) {
    .standard-styles .kba__footer {
      padding: 24px 40px 24px 80px;
      height: 96px;
      flex-direction: row; }
      .standard-styles .kba__footer .label-box {
        text-align: left;
        display: block; }
        .standard-styles .kba__footer .label-box .label {
          font-size: 24px; }
      .standard-styles .kba__footer .button {
        margin-top: 0; } }
